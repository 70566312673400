// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
// Custom Components
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
// Assets
import { IoMdMoon, IoMdSunny } from "react-icons/io";
import routes from "routes";
import { getAuth } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/store";
import { NavLink } from "react-router-dom";
export default function HeaderLinks(props: any) {
  const auth = getAuth();
  const toast = useToast();
  const { secondary } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  const profile = useSelector((state: IRootState) => state.profile);
  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  const handleLogout = async () => {
    await auth.signOut();
    toast({
      title: "Logged Out.",
      description: "Goodbye 👋",
      status: "success",
      duration: 9000,
      isClosable: true,
      position: "top-right",
    });
  };

  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      <Flex
        justifyContent={"space-between"}
        w="100%"
        alignItems={"center"}
        height="100%"
      >
        <Flex align="center" direction="column" mr={"20px"}>
          <Flex justify="center" align="center" ml={"20px"}>
            <Text fontSize="22px" fontWeight="1000" fontFamily="DM sans">
              Sty.
            </Text>
            <Text fontSize="22px">re</Text>
          </Flex>
        </Flex>
        <Flex alignItems={'center'}>
          <SidebarResponsive routes={routes} />
          <Button
            ml="15px"
            mr="15px"
            variant="no-hover"
            bg="transparent"
            height={"100%"}
            p="0px"
            minW="unset"
            minH="unset"
            h="100%"
            w="max-content"
            onClick={toggleColorMode}
          >
            <Icon
              me="10px"
              h="18px"
              w="18px"
              color={navbarIcon}
              as={colorMode === "light" ? IoMdMoon : IoMdSunny}
            />
          </Button>
          <Menu>
            <MenuButton p="0px">
              <Avatar
                _hover={{ cursor: "pointer" }}
                color="white"
                name={`${profile.firstName} ${profile.lastName}`}
                src={profile.profilePhotoUrl}
                bg="#11047A"
                size="sm"
                w="40px"
                h="40px"
              />
            </MenuButton>
            <MenuList
              boxShadow={shadow}
              p="0px"
              mt="10px"
              borderRadius="20px"
              bg={menuBg}
              border="none"
            >
              <Flex w="100%" mb="0px">
                <Text
                  ps="20px"
                  pt="16px"
                  pb="10px"
                  w="100%"
                  borderBottom="1px solid"
                  borderColor={borderColor}
                  fontSize="sm"
                  fontWeight="700"
                  color={textColor}
                >
                  👋&nbsp; Hey, {profile.firstName}
                </Text>
              </Flex>
              <Flex flexDirection="column" p="10px">
                <NavLink to={"/admin/profile"}>
                  <MenuItem
                    _hover={{ bg: "none" }}
                    _focus={{ bg: "none" }}
                    borderRadius="8px"
                    px="14px"
                  >
                    <Text fontSize="sm">Profile Settings</Text>
                  </MenuItem>
                </NavLink>
                <MenuItem
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                  color="red.400"
                  borderRadius="8px"
                  px="14px"
                >
                  <Text fontSize="sm" onClick={handleLogout}>
                    Log out
                  </Text>
                </MenuItem>
              </Flex>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
  logoText: PropTypes.string,
};
