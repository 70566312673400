import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdHome,
  MdLock,
} from "react-icons/md";

// Admin Imports
import Links from "views/links/default";
import Profile from "views/profile";

// Auth Imports
import SignInCentered from "views/auth/signIn";

const routes = [
  {
    name: "Links",
    layout: "/admin",
    path: "/links",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: <Links/>,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: <Profile/>,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: <SignInCentered/>,
  }
];

export default routes;
