import { CheckIcon, EditIcon } from "@chakra-ui/icons";
import {
  Editable,
  Box,
  useEditableControls,
  Flex,
  IconButton,
  EditablePreview,
  Input,
  EditableInput
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setDraftMode, updateSectionTitle } from "redux/slice/profileLinksSlice";
import { IRootState } from "redux/store";

interface EditableSectionTitleProps {
  weakId: string;
}

export default function EditableSectionTitle(props: EditableSectionTitleProps) {
  const dispatch = useDispatch();
  const title = useSelector(
    (state: IRootState) => state.profileLinks.sections.byWeakId[props.weakId].title
  );
  const isDraftMode = useSelector(
    (state: IRootState) => state.profileLinks.isDraftMode
  );

  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <Flex mr="10px" justifyContent="center">
        <IconButton
          size="sm"
          icon={<CheckIcon />}
          {...(getSubmitButtonProps() as any)}
        />
      </Flex>
    ) : (
      <Flex justifyContent="center" mr="10px">
        <IconButton
          size="sm"
          icon={<EditIcon />}
          {...(getEditButtonProps() as any)}
        />
      </Flex>
    );
  }

  const sectionTitleChanged = (newTitle: string) => {
    if (!isDraftMode) {
      dispatch(setDraftMode(true));
    }
    dispatch(
      updateSectionTitle({
        id: props.weakId,
        value: newTitle,
      })
    );
  };

  return (
    <Editable
      textAlign='center'
      value={title}
      fontSize='2xl'
      fontWeight={'500'}
      ml={'10px'}
      isPreviewFocusable={false}
      onChange={sectionTitleChanged}
  >
      <Box display={'flex'} alignItems='center'>
          <EditableControls/>
          <EditablePreview />
          <Input as={EditableInput} />
      </Box>
  </Editable>
  );
}
