import { Box, useColorMode, Text } from "@chakra-ui/react";
import { SocialIcon } from "react-social-icons";
import { OTHER_LINK_TYPE } from "types/links/linkType";
import * as allIcons from "react-icons/fa";

interface LinkIconProps {
  typeId: string;
  large?: boolean;
  customColor?: string;
  customIconName?: string | null;
}

export default function LinkIcon(props: LinkIconProps) {
  const { colorMode } = useColorMode();
  const customColor = colorMode === "dark" ? "white" : props.customColor || "";

  const getIcon = (typeId: string): JSX.Element => {
    const style = {
      height: props.large ? 60 : 25,
      width: props.large ? 60 : 25,
      fill: colorMode === "dark" ? "white" : "none",
    };
    if (typeId !== OTHER_LINK_TYPE.id) {
      return (
        <SocialIcon
          network={typeId}
          style={style}
          bgColor={colorMode === "dark" ? "white" : ""}
        />
      );
    }

    const matchingCustomIcons = Object.entries(allIcons).find((ic) => ic[0] === props.customIconName);
    let customIcon = null;
    if (matchingCustomIcons && matchingCustomIcons.length > 0) {
      customIcon = matchingCustomIcons[1];
    }

    return (
      <Box>
        {customIcon && (
          <Box>
            {
              customIcon({size: '3.5em', color: customColor})
            }
          </Box>
        )}
        {!customIcon && (
          <SocialIcon
            network={undefined}
            style={style}
            bgColor={customColor}
          />
        )}
      </Box>
    );
  };

  return <Box>{getIcon(props.typeId)}</Box>;
}
