import { CheckIcon, EditIcon } from "@chakra-ui/icons";
import {
  Editable,
  Box,
  useEditableControls,
  Flex,
  IconButton,
  EditablePreview,
  Input,
  EditableInput
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { updateMasterLinkTitle } from "redux/slice/profileLinksSlice";
import { IRootState } from "redux/store";
import MasterLinkTitle from "./MasterLinkTitle";

interface EditableMasterLinkTitleProps {
  weakId: string;
  editing: boolean;
}

export default function EditableMasterLinkTitle(props: EditableMasterLinkTitleProps) {
  const dispatch = useDispatch();
  const title = useSelector(
    (state: IRootState) => state.profileLinks.masterLinks.byWeakId[props.weakId].title
  );

  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <Flex mr="10px" justifyContent="center">
        <IconButton
          size="sm"
          icon={<CheckIcon />}
          {...(getSubmitButtonProps() as any)}
        />
      </Flex>
    ) : (
      <Flex justifyContent="center" mr="10px">
        <IconButton
          size="sm"
          icon={<EditIcon />}
          {...(getEditButtonProps() as any)}
        />
      </Flex>
    );
  }

  const onMasterLinkTitleChanged = (newTitle: string) => {
    dispatch(
      updateMasterLinkTitle({
        id: props.weakId,
        value: newTitle,
      })
    );
  };

  return (
    <Editable
      textAlign="center"
      value={title}
      fontSize="2xl"
      fontWeight={"500"}
      ml={"10px"}
      isPreviewFocusable={false}
      onChange={onMasterLinkTitleChanged}
    >
      {props.editing && (
        <Box display={"flex"} alignItems="center">
          <EditableControls />
          <EditablePreview />
          <Input as={EditableInput} />
        </Box>
      )}

      {!props.editing && (
        <MasterLinkTitle weakId={props.weakId} />
      )}
    </Editable>
  );
}
