/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Grid, Portal, useDisclosure } from "@chakra-ui/react";

// Custom components
import Banner from "views/profile/components/Banner";
import General from "views/profile/components/General";
import ActionCenter from "views/profile/components/ActionCenter";

// Assets
import { useState } from "react";
import Navbar from "components/navbar/NavbarAdmin";
import { getActiveNavbar, getActiveNavbarText, getActiveRoute } from "utils/navigation/navbar";
import routes from "routes";
import { getAuth } from "firebase/auth";
import { useSelector } from "react-redux";
import { IRootState } from "redux/store";
import SiteOptions from "./components/SiteOptions";

export default function Overview(props: any) {
  const { onOpen } = useDisclosure();
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const auth = getAuth();
  //Profile
  const firstName = useSelector((state: IRootState) => state.profile.firstName);
  const lastName = useSelector((state: IRootState) => state.profile.lastName);
  const bio = useSelector((state: IRootState) => state.profile.bio);
  const occupation = useSelector((state: IRootState) => state.profile.occupation);
  const email = useSelector((state: IRootState) => state.profile.email);
  const coverPhotoUrl = useSelector((state: IRootState) => state.profile.coverPhotoUrl);
  const profilePhotoUrl = useSelector((state: IRootState) => state.profile.profilePhotoUrl);

  const getName = (): string => {
    let res = "";
    if (!firstName && !lastName) {
      return "No Name";
    }
    if (firstName) {
      res = firstName;
    }
    if (lastName) {
      res = `${res} ${lastName}`;
    }
    return res;
  }
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Portal>
        <Box>
          <Navbar
            onOpen={onOpen}
            logoText={"Styre"}
            brandText={getActiveRoute(routes)}
            secondary={getActiveNavbar(routes)}
            message={getActiveNavbarText(routes)}
            fixed={fixed}
            {...rest}
          />
        </Box>
      </Portal>
      {/* Main Fields */}
      <Grid
      mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "1.5fr 1fr",
        }}
        templateRows={{
          base: "repeat(2, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Banner
          gridArea='1 / 1 / 2 / 2'
          banner={coverPhotoUrl}
          avatar={profilePhotoUrl}
          email={email}
          name={getName()}
          job={occupation}
          bio={bio}
        />
        <SiteOptions
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
        />
      </Grid>
      <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "1.5fr 1fr",
          "2xl": "1.5fr 1fr",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(1, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <General
          gridArea={{ base: "1 / 1 / 1 / 1", lg: "1 / 1 / 1 / 1" }}
          minH='365px'
          pe='20px'
          firstName={firstName}
          lastName={lastName}
          bio={bio}
          occupation={occupation}
          email={email}
          coverPhotoUrl={coverPhotoUrl}
          profilePhotoUrl={profilePhotoUrl}

        />
        <ActionCenter
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
        />
      </Grid>
    </Box>
  );
}
