import { createSlice } from "@reduxjs/toolkit";
import { getAuth } from "firebase/auth";
import { IProfile } from "types/profile/profile";

const auth = getAuth();

interface IProfileState extends IProfile {

}

const initialState: IProfileState = {
  firebaseId: "",
  firstName: "",
  lastName: "",
  email: "",
  bio: "",
  occupation: "",
  coverPhotoUrl: "",
  profilePhotoUrl: "",
  useGooglePhoto: false
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfileProfilePhotoUrl: (state, action) => {
      state.profilePhotoUrl = action.payload;
    },
    setProfileCoverPhotoUrl: (state, action) => {
      state.coverPhotoUrl = action.payload;
    },
    saveProfile: (state, action) => {
      if (!auth.currentUser) {
        throw new Error("Profile update failed, as user was not logged in.")
      }
      const profile: IProfile = JSON.parse(action.payload);
      state.firebaseId = auth.currentUser.uid;
      state.firstName = profile.firstName || "";
      state.lastName = profile.lastName || "";
      state.email = profile.email || "";
      state.bio = profile.bio || "";
      state.occupation = profile.occupation || "";
      state.coverPhotoUrl = profile.coverPhotoUrl || "";
      state.profilePhotoUrl = profile.profilePhotoUrl || "";
      state.useGooglePhoto = profile.useGooglePhoto || false;
    }
  },
});

// Action creators are generated for each case reducer function
export const { saveProfile, setProfileCoverPhotoUrl, setProfileProfilePhotoUrl } = profileSlice.actions;

export default profileSlice.reducer;