// Chakra imports
import {
    Box,
    Button,
    Flex,
    Grid,
} from "@chakra-ui/react";
import { DndContext, DragEndEvent, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
// Custom components
import Card from "components/card/Card";
// Assets
import {
    arrayMove,
    SortableContext,
    rectSortingStrategy
} from "@dnd-kit/sortable";
import SortableLinkContainer from "./SortableLinkContainer";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/store";
import { removeMasterLink, reOrderMasterLinks, setDraftMode } from "redux/slice/profileLinksSlice";
import EditableSectionTitle from "./EditableSectionTitle";
import AddMasterLinkButton from "./AddMasterLinkButton";

interface SectionProps {
    weakId: string,
    deleteSection: (id: string) => void
}

export default function Section(props: SectionProps) {
    const dispatch = useDispatch();
    // Chakra Color Mode
    const masterLinkWeakIds: string[] = useSelector((state: IRootState) => state.profileLinks.sections.byWeakId[props.weakId].masterLinkWeakIds);

    const deleteMasterLink = (mastrLinkWeakId: string): void => {
        dispatch(removeMasterLink({
            masterLinkWeakId: mastrLinkWeakId,
        }));
    }

    const deleteSection = () => {
        props.deleteSection(props.weakId);
    }

    const handleDragEnd = (result: DragEndEvent) => {
        if (!result.over) {
            return;
        }
        if (result.active.id !== result.over.id) {
            const activeContainer = result.active.data.current!.sortable.containerId;
            const overContainer = result.over.data.current?.sortable.containerId || result.over.id;
            const activeIndex = result.active.data.current!.sortable.index;
            const overIndex = result.over.data.current?.sortable.index || 0;

            let newOrder = [...masterLinkWeakIds];
            if (activeContainer === overContainer) {
                newOrder = arrayMove(newOrder, activeIndex, overIndex);
                dispatch(reOrderMasterLinks({
                    weakId: props.weakId,
                    value: newOrder
                }))
                dispatch(setDraftMode(true));
            }
        }
    };

    const mouseSensor = useSensor(MouseSensor)
    const touchSensor = useSensor(TouchSensor)
    const sensors = useSensors(mouseSensor, touchSensor)

    return (
        <Card mb={{ base: "20px", lg: "20px" }} align="start">
            <Flex w='100%' justifyContent={{base: 'end', md: 'space-between'}}  flexDirection={{base: 'column', md: 'row'}}>
                <EditableSectionTitle weakId={props.weakId}/>
                <Flex justifyContent={{base: 'end', md: 'space-between'}} alignItems='center' mb={{base: '0', md: '24px'}} >
                    <AddMasterLinkButton weakId={props.weakId}/>
                    <Button
                        fontSize="sm"
                        variant="outline"
                        fontWeight="500"
                        h="50"
                        onClick={deleteSection}
                    >
                        Delete Section
                    </Button>
                </Flex>
            </Flex>
            <Box>
                <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
                    <SortableContext items={masterLinkWeakIds} strategy={rectSortingStrategy}>
                        <Grid
                            display='inline-grid'
                            gridTemplateColumns={{
                                base: '1fr',
                                lg: "repeat(2, 1fr)",
                                '2xl': "repeat(3, 1fr)"
                            }}
                            gridGap='10'
                            w='100%'
                        >
                            {masterLinkWeakIds.map((mlWid) => (
                                <SortableLinkContainer key={mlWid} weakId={mlWid} sectionWeakId={props.weakId} handle={true} deleteLink={deleteMasterLink} />
                            ))}
                        </Grid>
                    </SortableContext>
                </DndContext>
            </Box>
        </Card>
    );
}
