import LinkIcon from './LinkIcon';
import { useSelector } from "react-redux";
import { IRootState } from "redux/store";

interface MasterLinkIconProps {
  weakId: string;
}

export default function MasterLinkIcon(props: MasterLinkIconProps) {
  const linkTypeId = useSelector(
    (state: IRootState) => state.profileLinks.masterLinks.byWeakId[props.weakId].type
  );
  const customColor = useSelector(
    (state: IRootState) => state.profileLinks.masterLinks.byWeakId[props.weakId].customColor
  );

  const customIconName = useSelector(
    (state: IRootState) => state.profileLinks.masterLinks.byWeakId[props.weakId].customIcon || null
  );

  return (
    <LinkIcon typeId={linkTypeId} customIconName={customIconName} large={true} customColor={customColor || undefined}/>
  );
}
