import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./slice/authSlice";
import profileReducer from "./slice/profileSlice";
import profileLinkReducer from "./slice/profileLinksSlice"
import homepageReducer from "./slice/homePageSlice"
export const store = configureStore({
  reducer: {
    auth: authReducer,
    profile: profileReducer,
    profileLinks: profileLinkReducer,
    homepage: homepageReducer
  },
});

const rootReducer = combineReducers({ auth: authReducer, profile: profileReducer, profileLinks: profileLinkReducer, homepage: homepageReducer });

export type IRootState = ReturnType<typeof rootReducer>;