import { ReactNode, useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Box,
  Button,
  Flex,
  Divider,
  useColorMode,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  FormLabel,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { DragHandleIcon, EditIcon, CloseIcon } from "@chakra-ui/icons";
import { OTHER_LINK_TYPE } from "types/links/linkType";
import { BsTrash } from "react-icons/bs";
import Card from "components/card/Card";
import { batch, useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/store";
import {
  addEmptyLink,
  removeLink as rmLink,
  updateLinkTitle,
  updateLinkUrl,
  setDraftMode,
  addEditingMasterLinkWid,
  removeEditingMasterLinkWid,
  addCustomColor,
} from "redux/slice/profileLinksSlice";
import LinkValueField from "./LinkValueField";
import { Selector } from "@reduxjs/toolkit";
import LinkButton from "./LinkButton";
import ValidCheckButton from "./ValidCheckButton";
import MasterLinkTitle from "./MasterLinkTitle";
import EditableMasterLinkTitle from "./EditableMasterLinkTitle";
import LinkSelect from "./LinkSelect";
import MasterLinkIcon from "./MasterLinkIcon";
import { ChromePicker, ColorResult, GithubPicker, SketchPicker, SwatchesPicker, TwitterPicker } from "react-color";
import IconSelect from "./IconSelect";

interface SortableItemProps {
  weakId: string; // WeakId of masterlink
  sectionWeakId: string;
  handle: boolean;
  deleteLink: (weakId: string) => void;
}

const SortableItem = (props: SortableItemProps) => {
  const dispatch = useDispatch();
  const { colorMode } = useColorMode();
  const textColorSecondary = "gray.400";
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const [closeOnNextSaveLoadingFalse, setCloseOnNextSaveLoadingFalse] =
    useState(false);
    const { onOpen, onClose, isOpen } = useDisclosure()

  const primaryLinkWeakId = useSelector(
    (state: IRootState) =>
      state.profileLinks.masterLinks.byWeakId[props.weakId].primaryLinkWeakId
  );
  const linkType = useSelector(
    (state: IRootState) =>
      state.profileLinks.masterLinks.byWeakId[props.weakId].type
  );
  const linkColor = useSelector(
    (state: IRootState) =>
      state.profileLinks.masterLinks.byWeakId[props.weakId].customColor
  );
  const linkWeakIds = useSelector(
    (state: IRootState) =>
      state.profileLinks.masterLinks.byWeakId[props.weakId].linkWeakIds
  );
  const saveLoading = useSelector(
    (state: IRootState) => state.profileLinks.saveLoading
  );
  const editing = useSelector((state: IRootState) =>
    state.profileLinks.sections.byWeakId[
      props.sectionWeakId
    ].editingMasterLinkWeakIds.includes(props.weakId)
  );
  const canDrag = useSelector(
    (state: IRootState) =>
      state.profileLinks.sections.byWeakId[props.sectionWeakId]
        .editingMasterLinkWeakIds.length === 0
  );

  const enterEditMode = () => {
    batch(() => {
      dispatch(setDraftMode(true));
      dispatch(
        addEditingMasterLinkWid({
          id: props.sectionWeakId,
          value: props.weakId,
        })
      );
    });
  };

  useEffect(() => {
    if (closeOnNextSaveLoadingFalse && !saveLoading) {
      dispatch(
        removeEditingMasterLinkWid({
          id: props.sectionWeakId,
          value: props.weakId,
        })
      );
      setCloseOnNextSaveLoadingFalse(false);
    } else if (!closeOnNextSaveLoadingFalse && saveLoading) {
      setCloseOnNextSaveLoadingFalse(true);
    }
  }, [
    closeOnNextSaveLoadingFalse,
    dispatch,
    props.sectionWeakId,
    props.weakId,
    saveLoading,
  ]);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props.weakId });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    minHeight: "100px",
    border: "2px solid #DDD",
    borderRadius: "10px",
    margin: "10px",
    zIndex: isDragging ? "100" : "auto",
    opacity: isDragging ? 0.3 : 1,
    width: editing ? "100%" : "auto",
  };

  const exitEditMode = () => {
    dispatch(
      removeEditingMasterLinkWid({
        id: props.sectionWeakId,
        value: props.weakId,
      })
    );
  };

  const addLink = () => {
    dispatch(
      addEmptyLink({
        masterLinkWeakId: props.weakId,
        linkType: OTHER_LINK_TYPE.id,
      })
    );
  };

  const canAddLink = () => {
    return linkWeakIds.length < 3;
  };

  const getLinkTitleSelector = (weakId: string): Selector => {
    return (state: IRootState) =>
      state.profileLinks.links.byWeakId[weakId].title;
  };

  const onLinkTitleChanged = (weakId: string, value: string) => {
    dispatch(
      updateLinkTitle({
        id: weakId,
        value,
      })
    );
  };

  const getLinkUrlSelector = (weakId: string): Selector => {
    return (state: IRootState) => state.profileLinks.links.byWeakId[weakId].url;
  };

  const onLinkUrlChanged = (weakId: string, value: string) => {
    dispatch(
      updateLinkUrl({
        id: weakId,
        value,
      })
    );
  };

  const removeLink = (weakId: string) => {
    dispatch(
      rmLink({
        linkWeakId: weakId,
      })
    );
  };

  const getUserLinksEdit = (): ReactNode[] => {
    return linkWeakIds.map((lWid, index) => {
      return (
        <Box mt="10px" key={index}>
          <Divider />
          <Box>
            <Flex
              pt={"10px"}
              mb={"-20px"}
              onClick={() => removeLink(lWid)}
              cursor={"pointer"}
            >
              <BsTrash color={colorMode === "dark" ? "white" : "darkred"} />
            </Flex>
            <LinkValueField
              isTitle={true}
              weakId={lWid}
              label={"Link Title"}
              onChange={onLinkTitleChanged}
              selector={getLinkTitleSelector(lWid)}
              placeholder={"Title"}
            />
            <Box mt={"5px"}>
              <LinkValueField
                isTitle={false}
                weakId={lWid}
                label={"Link Url"}
                onChange={onLinkUrlChanged}
                selector={getLinkUrlSelector(lWid)}
                placeholder={"Url"}
              />
            </Box>
          </Box>
        </Box>
      );
    });
  };

  const getUserLinksView = (): ReactNode[] => {
    return linkWeakIds.map((lWid) => {
      return <LinkButton weakId={lWid} key={lWid} />;
    });
  };

  const deleteMasterLink = () => {
    exitEditMode();
    props.deleteLink(props.weakId);
    dispatch(setDraftMode(true));
  };

  const colorChangeHandler = (color: ColorResult) => {
    dispatch(
      addCustomColor({
        id: props.weakId,
        value: color.hex,
      })
    );
  };

  return (
    <Box ref={setNodeRef} style={style}>
      <Card>
        <Box display={"flex"} justifyContent="space-between">
          {canDrag && (
            <Box
              {...listeners}
              {...attributes}
              cursor={isDragging ? "grabbing" : "grab"}
              minW="30%"
              display="flex"
              justifyContent="left"
              alignItems={"center"}
            >
              <DragHandleIcon
                w="35px"
                h="35px"
                p="10px"
                color={textColorSecondary}
              />
              <MasterLinkTitle weakId={props.weakId} />
            </Box>
          )}
          {!canDrag && (
            <Box
              minW="30%"
              display="flex"
              justifyContent="left"
              alignItems={"center"}
            >
              <EditableMasterLinkTitle
                weakId={props.weakId}
                editing={editing}
              />
            </Box>
          )}
          {editing && (
            <Flex justifyContent={"end"} alignItems="center" w={"100%"}>
              <Box cursor="pointer" padding="12px" onClick={deleteMasterLink}>
                <CloseIcon
                  w="15px"
                  h="15px"
                  color={textColorSecondary}
                ></CloseIcon>
              </Box>
            </Flex>
          )}
          {!editing && (
            <Flex justifyContent="space-between" alignItems="center">
              <Box cursor="pointer" padding="10px" onClick={enterEditMode}>
                <EditIcon
                  w="20px"
                  h="20px"
                  color={textColorSecondary}
                ></EditIcon>
              </Box>
              <Box cursor="pointer" padding="12px" onClick={deleteMasterLink}>
                <CloseIcon
                  w="15px"
                  h="15px"
                  color={textColorSecondary}
                ></CloseIcon>
              </Box>
            </Flex>
          )}
        </Box>
        {editing && (
          <Flex flexDirection={"column"} w="100%" alignItems={"end"}>
            <FormLabel
              display="block"
              ms="4px"
              fontSize="sm"
              fontWeight="1000"
              color={textColorPrimary}
              mb="8px"
            >
              Link Type
            </FormLabel>
            <LinkSelect weakId={props.weakId} />
          </Flex>
        )}
        {editing && linkType === OTHER_LINK_TYPE.id && (
          <Flex
            w={"100%"}
            mt="10px"
            mb={"5px"}
            flexDirection="column"
            alignItems={"end"}
          >
            <FormLabel
              display="block"
              ms="4px"
              fontSize="sm"
              fontWeight="1000"
              color={textColorPrimary}
              mb="8px"
            >
              Link Icon
            </FormLabel>
            <IconSelect weakId={props.weakId} />
            <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
              <PopoverTrigger>
                <Button
                  fontSize="sm"
                  size="sm"
                  mt="10px"
                  fontWeight="500"
                  h="40px"
                  w={"100%"}
                  bg={linkColor || 'green'}
                >
                  Select Color
                </Button>
              </PopoverTrigger>
              <PopoverContent w={"305px"}>
                <PopoverArrow />
                <PopoverBody>
                  <SwatchesPicker
                    className="colorpicker"
                    onChangeComplete={colorChangeHandler}
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
        )}
        <Flex w="100%" justifyContent={"space-between"}>
          {!editing && (
            <Box p={"20px 10px"}>
              <MasterLinkIcon weakId={props.weakId} />
            </Box>
          )}
          <Box w={"100%"}>
            {editing && (
              <Box pt="10px">
                <LinkValueField
                  isTitle={true}
                  weakId={primaryLinkWeakId}
                  label={"Primary Link Title"}
                  onChange={onLinkTitleChanged}
                  selector={getLinkTitleSelector(primaryLinkWeakId)}
                  placeholder={"Title"}
                />
                <Box mt="5px">
                  <LinkValueField
                    isTitle={false}
                    weakId={primaryLinkWeakId}
                    label={"Primary Link Url"}
                    onChange={onLinkUrlChanged}
                    selector={getLinkUrlSelector(primaryLinkWeakId)}
                    placeholder={"Url"}
                  />
                </Box>
              </Box>
            )}

            {!editing && (
              <Box pt="10px" w={"100%"}>
                <LinkButton
                  weakId={primaryLinkWeakId}
                  masterLinkWid={props.weakId}
                />
                {getUserLinksView()}
              </Box>
            )}

            {editing && getUserLinksEdit()}

            {editing && canAddLink() && (
              <Box display="flex" justifyContent="end" mt="24px">
                <Button
                  fontSize="sm"
                  variant="brand"
                  size="sm"
                  fontWeight="500"
                  h="40px"
                  onClick={addLink}
                >
                  New Link
                </Button>
              </Box>
            )}
          </Box>
        </Flex>

        {editing && (
          <ValidCheckButton
            masterLinkWeakId={props.weakId}
            onClick={exitEditMode}
          />
        )}
      </Card>
    </Box>
  );
};

export default SortableItem;
