import { ILink, IMasterLink } from "../types/links/links";
import { store } from "../redux/store";

export function isValidMasterLinkByWid(masterLinkWid: string): boolean {
    const masterLink = store.getState().profileLinks.masterLinks.byWeakId[masterLinkWid];
    const primaryLink = store.getState().profileLinks.links.byWeakId[masterLink.primaryLinkWeakId];
    const links = masterLink.linkWeakIds.map(lWid => store.getState().profileLinks.links.byWeakId[lWid]);
    
    if (!isValidMasterLink(masterLink)) {
        return false;
    }
    if (!isValidPrimaryLink(primaryLink)) {
        return false;
    }
    if (!isValidLinks(links)) {
        return false;
    }
    return true;
}

export function isValidMasterLink(masterLink: IMasterLink): boolean {
    if (masterLink.title === "") {
        return false;
    }
    if (!masterLink.sectionWeakId) {
        return false;
    }
    if (!masterLink.type) {
        return false;
    }
    return true;
}

export function isValidPrimaryLink(link: ILink): boolean {
    return link.title.length > 0 && link.url.length > 0;
}

export function isValidLinks(links: ILink[]): boolean {
    let valid = true;
    for (const link of links) {
        valid = isValidLink(link);
        if (!valid) {
            return false;
        }
    }
    return true;
}

export function isValidLink(link: ILink): boolean {
    return link.title.length > 0 && link.url.length > 0;
}