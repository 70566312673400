import {
  Button,
} from "@chakra-ui/react";
import { batch, useDispatch, useSelector } from "react-redux";
import { addEditingMasterLinkWid, addMasterLink, setDraftMode } from "redux/slice/profileLinksSlice";
import { IRootState } from "redux/store";
import { v4 as uuidv4} from 'uuid';

interface AddMasterLinkButtonProps {
  weakId: string;
}

export default function AddMasterLinkButton(props: AddMasterLinkButtonProps) {
  const dispatch = useDispatch();
  const masterLinkWeakIds = useSelector((state: IRootState) => state.profileLinks.sections.byWeakId[props.weakId].masterLinkWeakIds);

  const addNewMasterLink = () => {
    const mlId = uuidv4();
    batch(() => {
      dispatch(setDraftMode(true));
      dispatch(addMasterLink({
          sectionWeakId: props.weakId,
          masterLinkId: mlId,
      }));
      dispatch(addEditingMasterLinkWid({
        id: props.weakId,
        value: mlId
      }));
    })
}

  return (
    <Button
      fontSize="sm"
      variant="brand"
      fontWeight="500"
      h="50"
      mr='10px'
      onClick={addNewMasterLink}
      disabled={masterLinkWeakIds.length >= 6}
  >
      {masterLinkWeakIds.length >= 6 ? 'Maximum links reached' : 'New Link'}
  </Button>
  );
}
