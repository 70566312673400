// Chakra imports
import {
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "redux/store";
import { deleteProfileAndSignOut } from "utils/api/apiConnector";
// Assets

export default function ActionCenter(props: any) {
  const { used, total } = props;
  const auth = getAuth();
  const toast = useToast();
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const siteName = useSelector((state: IRootState) => state.homepage.name);

  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const {
    isOpen: deleteModalIsOpen,
    onOpen: deleteModalOnOpen,
    onClose: deleteModalOnClose,
  } = useDisclosure();

  const handleResetPassword = () => {
    if (!auth.currentUser || !auth.currentUser.email) {
      return;
    }
    setResetPasswordLoading(true);
    sendPasswordResetEmail(auth, auth.currentUser.email)
      .then(() => {
        setResetPasswordLoading(false);
        toast({
          title: "Forgetful?",
          description: "We've sent you an email to reset your password.",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      })
      .catch((error) => {
        setResetPasswordLoading(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error ocured: ", errorCode, errorMessage);
        toast({
          title: "An error occurred.",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      });
  };

  const deleteProifleAndSignOut = async () => {
    await deleteProfileAndSignOut();
    toast({
      title: "Profile was deleted",
      description: "We'll miss you!",
      status: "success",
      duration: 9000,
      isClosable: true,
      position: "top-right",
    });
  };

  const deleteMeClicked = () => {
    deleteModalOnOpen();
    return;
  };

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align="start">
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="10px"
        mb="20px"
      >
        Action Center
      </Text>
      <Flex
        direction="column"
        h="100%"
        alignContent="end"
        justifyContent="start"
      >
        {siteName && (
          <Link href={`https://sty.re/${siteName}`} isExternal>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              mb="24px"
            >
              Visit my site
            </Button>
          </Link>
        )}
        <Button
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="100%"
          mb="24px"
          isLoading={resetPasswordLoading}
          onClick={handleResetPassword}
        >
          Reset Password
        </Button>
        <Button
          fontSize="sm"
          fontWeight="500"
          variant={"danger"}
          w="100%"
          mb="24px"
          onClick={deleteMeClicked}
        >
          Delete Profile
        </Button>
        <Modal
          isCentered
          isOpen={deleteModalIsOpen}
          onClose={deleteModalOnClose}
          size="xl"
        >
          <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
          <ModalContent>
            <ModalHeader>Oh no 😢</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontWeight={"bold"}>
                Are you sure you want to delete your profile?
              </Text>
              <Text>
                All data from your profile will be deleted, and you will not be
                able to recover it. If you come back, you will need to set up
                everything from scratch.
              </Text>
            </ModalBody>
            <ModalFooter>
              <Box w={"100%"}>
                <Button
                  onClick={deleteModalOnClose}
                  variant="brand"
                  w={"100%"}
                  mb="20px"
                >
                  No, take me back
                </Button>
                <Flex w={"100%"} justifyContent="end">
                  <Button onClick={deleteProifleAndSignOut} variant="danger">
                    Yes, permanently delete my account
                  </Button>
                </Flex>
              </Box>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Card>
  );
}
