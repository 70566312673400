import {
  Box,
  useColorModeValue,
  Button,
  Link,
  useColorMode,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { IRootState } from "redux/store";

interface LinkButtonProps {
  weakId: string;
  masterLinkWid?: string;
}

export default function LinkButton(props: LinkButtonProps) {
  const link = useSelector(
    (state: IRootState) => state.profileLinks.links.byWeakId[props.weakId]
  );
  const { colorMode } = useColorMode()
  const linkTypeId = useSelector((state: IRootState) => props.masterLinkWid ? state.profileLinks.masterLinks.byWeakId[props.masterLinkWid].type : null);
  const customColor = useSelector((state: IRootState) => props.masterLinkWid ? state.profileLinks.masterLinks.byWeakId[props.masterLinkWid].customColor : null);
  const color = useSelector((state: IRootState) =>  customColor ? customColor : linkTypeId ? state.profileLinks.linkTypes.find(lt => lt.id === linkTypeId)?.color : null);

  const bgColor = colorMode === 'dark' ? 'white' : color;
  const textColor = colorMode === 'dark' ? color : 'white';

  return (
    <Box>
      {color && (
        <Box display={"flex"} justifyContent="end" mr={"10px"} w="100%">
          <Link href={link.prepend + link.url} isExternal target={"_blank"} w='100%'>
            <Button bg={bgColor!} color={textColor!} _hover={{
                filter: 'brightness(0.8)'
            }} w="100%">
              {link.title || "Visit"}
            </Button>
          </Link>
        </Box>
      )}
      {!color && (
        <Box
          display={"flex"}
          key={props.weakId}
          justifyContent="end"
          mr={"10px"}
          w="100%"
          mt="10px"
        >
          <Link href={link.prepend + link.url}  isExternal target={"_blank"} w='100%'>
            <Button variant={"lightBrand"} w="100%">
              {link.title || "Visit"}
            </Button>
          </Link>
        </Box>
      )}
    </Box>
  );
}
