// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Skeleton,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { getAuth } from "firebase/auth";
import { useCallback, useState } from "react";
// Assets
import _ from "lodash";
import { checkHomePageUrl, publishOrRenewHomePage, unPublishHomePage } from "utils/api/apiConnector";
import { PublishOrRenewResponse } from "types/api";
import Countdown from 'react-countdown';
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/store";
import { setExpirationDate, setIsPublished, setName } from "redux/slice/homePageSlice";

export default function ActionCenter(props: any) {
  const auth = getAuth();
  const toast = useToast();
  const dispatch = useDispatch();
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const isPublished = useSelector((state: IRootState) => state.homepage.isPublished);
  const siteExpirationDate = useSelector((state: IRootState) => state.homepage.expirationDate);
  const siteName = useSelector((state: IRootState) => state.homepage.name);
  const [loading, setLoading] = useState(false);
  const [siteNameAvailabilityCheckLoading, setSiteNameAvailabilityCheckLoading] = useState(false);
  const [homePageNameIsValid, setHomePageNameIsValid] = useState(siteName.length > 0);
  const [homePageNameIsTaken, setHomePageNameIsTaken] = useState(false);
  const [newSiteNameSuggested, setNewSiteNameSuggested] = useState(false);
  const [originalSiteName, setOriginalSiteName] = useState(siteName);


  const checkSiteUrl = useCallback(_.debounce(async (newValue, originalValue) => {
    const result = (await checkHomePageUrl(newValue));
    setHomePageNameIsValid(result.valid);
    setHomePageNameIsTaken(result.taken);
    setSiteNameAvailabilityCheckLoading(false);
    if (newValue !== originalValue) {
      setNewSiteNameSuggested(true);
    }
    if (newValue === originalValue) {
      setNewSiteNameSuggested(false);
    }
  }, 600), []);

  const siteUrlChanged = (newValue: string) => {
    dispatch(setName(newValue));
    if (!siteNameAvailabilityCheckLoading) {
      setSiteNameAvailabilityCheckLoading(true);
    }
    checkSiteUrl(newValue, originalSiteName);
  }

  const validForPublish = !homePageNameIsTaken && homePageNameIsValid;

  const publishSite = async () => {
    setLoading(true);
    const res: PublishOrRenewResponse = await publishOrRenewHomePage(siteName);
    toast({
      title: res.success ? "Success" : "Ooops",
      description: res.success ? "Your site is now live 🙌" : <Box whiteSpace={'pre-line'}>Something went wrong...</Box>,
      status: res.success ? "success" : "error",
      duration: 9000,
      isClosable: true,
      position: "top-right",
    });
    if (res.success) {
      dispatch(setIsPublished(true));
      dispatch(setExpirationDate(res.expiration));
      setOriginalSiteName(siteName);
      setNewSiteNameSuggested(false);
    }
    setLoading(false);
  }

  const unPublishSite = async () => {
    setLoading(true);
    const res: {success: boolean} = await unPublishHomePage();
    toast({
      title: res.success ? "Success" : "Ooops",
      description: res.success ? "Your site is no longer published" : <Box whiteSpace={'pre-line'}>Something went wrong...</Box>,
      status: res.success ? "success" : "error",
      duration: 9000,
      isClosable: true,
      position: "top-right",
    });
    if (res.success) {
      dispatch(setIsPublished(false));
      dispatch(setExpirationDate(null));
    }
    setLoading(false);
  }

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align="start">
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="10px"
        mb="20px"
      >
        Site Options
      </Text>
      <Text color={textColorSecondary} fontSize="md" me="26px" mb="20px">
        Here you can change your site information, change your URL, or decide whether your site is visible or hidden from the public.
      </Text>
      <Flex
        direction="column"
        h="100%"
        alignContent="end"
        justifyContent="start"
      >
        <Flex>
          <FormControl>
            <Skeleton isLoaded={!loading} w='100%' borderRadius='15px'>
              <InputGroup>
                <InputLeftAddon children={<Text fontWeight={'500'} color={textColorSecondary} fontFamily='DM Sans' fontSize={'14px'} >https://sty.re/</Text>} h='48px' />
                <Input
                  isRequired={false}
                  variant="auth"
                  fontSize="sm"
                  type="url"
                  placeholder="mysite"
                  fontWeight="500"
                  disabled={loading}
                  size="lg"
                  value={siteName}
                  onChange={(e) => siteUrlChanged(e.target.value.toLowerCase())}
                />
                <InputRightElement width='4.5rem' h={'100%'}>
                  <Spinner transition={'1s ease all'} opacity={siteNameAvailabilityCheckLoading ? 1 : 0} color={textColorSecondary}>
                  </Spinner>
                </InputRightElement>
              </InputGroup>
            </Skeleton>
            {
              homePageNameIsTaken &&
              <FormHelperText color={'red'} fontSize="sm">The name has already been taken.
              </FormHelperText>
            }
            {
              !homePageNameIsValid && !homePageNameIsTaken &&
              <FormHelperText color={'red'} fontSize="sm">Minimum 5 characters, all lowercase. May include characters "-" and "_".
              </FormHelperText>
            }
            {
              homePageNameIsValid && !homePageNameIsTaken &&
              <FormHelperText color={'green'} fontSize="sm">The name is valid!
              </FormHelperText>
            }
          </FormControl>
        </Flex>
        {
          isPublished && siteExpirationDate &&
          <Flex direction={'column'} mt='20px'>
              <Flex>
              <Text fontSize={'14px'} color={textColorSecondary} mr='5px'>Your site will expire in</Text>
                <Countdown date={siteExpirationDate} renderer={({ days, hours, minutes, seconds, completed }) => (
                  <Text fontSize={'14px'} color={textColorSecondary}>{
                    (days > 0 ? `${days} days, ` : '') +
                    (days > 0 || hours > 0 ? `${hours} hours, ` : '') +
                    (days > 0 || hours > 0 || minutes > 0 ? `${minutes} minutes, ` : '') +
                    (seconds > 0 ? `${seconds} seconds.` : '')
                  }</Text>
                )} />
              </Flex>
              <Button mt="20px" isLoading={loading} variant={'success'} disabled={!validForPublish || loading} onClick={publishSite}>
                {
                  newSiteNameSuggested ? 'Change Site Name' : 'Renew lease'
                }
              </Button>
              <Button mt="20px" isLoading={loading} variant={'danger'} disabled={loading} onClick={unPublishSite}>Unpublish Site</Button>
              </Flex>
        }
        {
          !isPublished &&
          <Button mt="20px" isLoading={loading} variant={'success'} disabled={!validForPublish || loading} onClick={publishSite}>Publish Site</Button>
        }
      </Flex>
    </Card >
  );
}
