import React, { useEffect, useState } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import Links from "views/links/default";
import Profile from "views/profile";
import AuthLayout from "layouts/auth";
import DefaultLayout from "layouts/admin";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "redux/slice/authSlice";
import { IRootState } from "redux/store";
import { Box, Flex, Spinner, useColorModeValue, Text, Fade, useToast } from "@chakra-ui/react";
import { getAuthToken } from "utils/auth/auth";
import axios, { AxiosError, AxiosResponse } from "axios";
import { IProfile } from "types/profile/profile";
import { API_BASE_URL } from "const/api";
import { saveProfile } from "redux/slice/profileSlice";
import { LinkDataResponse } from "types/api";
import { populateLinksFromServer, saveLinkTypes } from "redux/slice/profileLinksSlice";
import { getHomePageForUser } from "utils/api/apiConnector";
import { setExpirationDate, setIsPublished, setName } from "redux/slice/homePageSlice";
import { LinkType } from "types/links/linkType";
import Privacy from "Privacy";
import Terms from "Terms";
import Tech from "Tech";

export default function App() {
  const toast = useToast();

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const [firstLaunch, setFirstLaunch] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const userId = useSelector((state: IRootState) => state.auth.userId);
  const userEmailVerified = useSelector((state: IRootState) => state.auth.emailVerified);
  const auth = getAuth();
  const dispatch = useDispatch();

  auth.beforeAuthStateChanged(() => {
    setInitialized(false);
  })
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      dispatch(updateUser(null));
      setUser(user);
      if (user) {
        initializeStore();
      } else {
        setInitialized(true);
      }
    });
  }, [auth, dispatch]);

  async function initializeStore(): Promise<void> {
    try {
      await loadProfile();
      await initializeLinks();
    } catch (e: any) {
      auth.signOut();
      toast({
        title: "An error occurred.",
        description: "Try logging in again",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      setInitialized(true);
    }

    setInitialized(true);
  }

  async function initializeLinks(): Promise<void> {
    const token = await getAuthToken(auth.currentUser!);
    const linkTypes: LinkType[] = await axios.get(
      API_BASE_URL + '/api/linktypes',
      { headers: { 'Authorization': token, 'Content-Type': "application/json" } }
    )
      .then((response: AxiosResponse<{linkTypes: LinkType[]}>) => {
        return response.data.linkTypes;
      })
      .catch((error: AxiosError) => {
        console.error(error);
        toast({
          title: "An error occurred.",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
        throw new Error("Failed to load link types");
      });
    dispatch(saveLinkTypes(linkTypes));
    const linkData: LinkDataResponse = await axios.get(
      API_BASE_URL + '/api/links',
      { headers: { 'Authorization': token, 'Content-Type': "application/json" } }
    )
      .then((response: AxiosResponse<LinkDataResponse>) => {
        return response.data;
      })
      .catch((error: AxiosError) => {
        console.error(error);
        toast({
          title: "An error occurred while fetching your links.",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
        throw new Error("Failed to load links");
      });
    dispatch(populateLinksFromServer(linkData));
    const homePageData = await getHomePageForUser();
    dispatch(setName(homePageData.name));
    dispatch(setExpirationDate(homePageData.expirationDate));
    dispatch(setIsPublished(homePageData.isPublished));
  }

  async function loadProfile(): Promise<void> {
      const token = await getAuthToken(auth.currentUser!);
      const profile: IProfile = await axios.get(
        API_BASE_URL + '/api/profile',
        { headers: { 'Authorization': token, 'Content-Type': "application/json" } }
      )
        .then((response: AxiosResponse<IProfile>) => {
          return response.data;
        })
        .catch((error: AxiosError) => {
          console.error(error);
          toast({
            title: "An error occurred.",
            description: error.message,
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
          throw new Error("Failed to load profile");
        });
    dispatch(saveProfile(JSON.stringify(profile)));
    return;
  }

  function userIsLoggedInAndVerified(): boolean {
    return userId !== null && userEmailVerified;
  }

  return (
    <React.StrictMode>
      <Box transition="1s all">
        <Fade in={!initialized} className='fader'>
          <Flex w='100vw' h={'110vh'} background='white' pos='absolute' top={0} left={0} height={initialized ? '0px' : '100vh'} zIndex={999} overflow='hidden' justifyContent='center' pt={initialized ? '0px' : '200px'}>
            <Flex flexDirection='column'>
              <Spinner
                alignSelf='center'
                mb='20px'
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color={textColorPrimary}
                size='xl'
              />
              <Text>Sty.re is loading...</Text>
            </Flex>
          </Flex>
        </Fade>
        <Fade in={initialized} className='fader'>
        <HashRouter>
          <Routes>
            <Route path={`/auth/*`} element={<AuthLayout />}></Route>
            <Route path="privacy" element={<Privacy />} />
            <Route path="terms" element={<Terms />} />
            <Route path="tech" element={<Tech />} />
            {userIsLoggedInAndVerified() ? (
              <Route path={`admin`} element={<DefaultLayout />}>
                <Route path="profile" element={<Profile />} />
                <Route path="links" element={<Links />} />
                <Route path="" element={<Navigate to={"links"} />} />
              </Route>
            ) : (
              <Route path={`admin/*`} element={<Navigate to="/auth" />} />
            )}
            <Route path="/" element={<Navigate to="/auth" />} />
          </Routes>
        </HashRouter>
      </Fade>
      </Box>
    </React.StrictMode>
  );
}
