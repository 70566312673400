import { ISection } from "./section";
import { v4 as uuidv4 } from "uuid";
import { OTHER_LINK_TYPE } from "./linkType";

export interface IProfileLinks {
    sections: ISection[];
}

export interface IMasterLink {
    _id: string | null;
    title: string;
    weakId: string;
    sectionWeakId: string;
    primaryLinkWeakId: string;
    linkWeakIds: string[];
    type: string;
    customColor: string | null;
    customIcon: string | null;
}

export const createEmptyMasterLink = (weakId: string, sectionWeakId: string, primaryLinkWeakId: string): IMasterLink => {
    return {
        _id: null,
        title: "Other",
        weakId,
        primaryLinkWeakId,
        linkWeakIds: [],
        sectionWeakId,
        type: OTHER_LINK_TYPE.id,
        customColor: null,
        customIcon: null
    }
}

export const createEmptyLink = (weakId: string, masterLinkWeakId: string, type: string): ILink => {
    return {
        _id: null,
        weakId,
        masterLinkWeakId,
        title: "Visit",
        url: "",
        type: OTHER_LINK_TYPE.id,
        prepend: 'https://'
    }
}

export interface ILink {
    _id: string | null;
    weakId: string; 
    masterLinkWeakId: string;
    type: string;
    title: string;
    url: string;
    prepend: string;
}