import {
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { IRootState } from "redux/store";
import { OTHER_LINK_TYPE } from "types/links/linkType";

interface MasterLinkTitleProps {
  weakId: string;
}

export default function MasterLinkTitle(props: MasterLinkTitleProps) {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const linkType = useSelector(
    (state: IRootState) => state.profileLinks.masterLinks.byWeakId[props.weakId].type
  );
  const linkTypeLabel = useSelector(
    (state: IRootState) => state.profileLinks.linkTypes.find(lt => lt.id === linkType)?.label
  );
  const title = useSelector(
    (state: IRootState) => state.profileLinks.masterLinks.byWeakId[props.weakId].title
  );

  return (
    <Text
      align={"center"}
      color={textColorPrimary}
      fontWeight="bold"
      fontSize="xl"
      ml="10px"
    >
      {linkType === OTHER_LINK_TYPE.id
        ? title
        : linkTypeLabel}
    </Text>
  );
}
