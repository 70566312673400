import { createSlice } from "@reduxjs/toolkit";
import { getAuth } from "firebase/auth";

type IAuthState = {
    userId: null | string,
    emailVerified: boolean
}

const initialState: IAuthState = {
    userId: null,
    emailVerified: false
};

const auth = getAuth();

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
        if (!auth.currentUser) {
            state.userId = null;
            state.emailVerified = false;    
            return;
        }
        state.userId = auth.currentUser.uid;
        state.emailVerified = auth.currentUser.emailVerified;
    }
  },
});

// Action creators are generated for each case reducer function
export const { updateUser } = authSlice.actions;

export default authSlice.reducer;