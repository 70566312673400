import { StringLiteralLike } from "typescript"

export interface LinkType {
    id: string,
    label: string,
    basePath: string,
    color: string
}

export const OTHER_LINK_TYPE = {
    label: "Other",
    id: 'other',
    basePath: "",
    color: '#00bf00'
}
