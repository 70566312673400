import { createSlice } from "@reduxjs/toolkit";
import { getAuth } from "firebase/auth";
import { IHomePage } from "types/homepage/homepage";

const auth = getAuth();

interface IHomePageState extends IHomePage {

}

const initialState: IHomePageState = {
  name: "",
  expirationDate: null,
  isPublished: false
};

export const homepageSlice = createSlice({
  name: "homepage",
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload
    },
    setExpirationDate: (state, action) => {
      state.expirationDate = action.payload
    },
    setIsPublished: (state, action) => {
      state.isPublished = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setName, setExpirationDate, setIsPublished } = homepageSlice.actions;

export default homepageSlice.reducer;