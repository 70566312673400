// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Skeleton,
  Text,
  Textarea,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import axios, { AxiosError, AxiosResponse } from "axios";
// Custom components
import Card from "components/card/Card";
import { API_BASE_URL } from "const/api";
import { getAuth } from "firebase/auth";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveProfile, setProfileCoverPhotoUrl, setProfileProfilePhotoUrl } from "redux/slice/profileSlice";
import { IRootState } from "redux/store";
import { IProfile } from "types/profile/profile";
import { getAuthToken } from "utils/auth/auth";
import { delay } from "utils/time/time";
import Information from "views/profile/components/Information";

// Assets
export default function GeneralInformation(props: any) {
  const { firstName, lastName, email, occupation, bio, coverPhotoUrl, profilePhotoUrl, ...rest } = props;
  const auth = getAuth();
  const toast = useToast();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [coverPhotoPreviewIsLoading, setCoverPhotoPreviewIsLoading] =
    useState(false);
  const [profilePhotoPreviewIsLoading, setProfilePhotoPreviewIsLoading] =
    useState(false);
  
  const [sEmail, setEmail] = useState(email);
  const [sOccupation, setOccupation] = useState(occupation);
  const [sFirstName, setFirstName] = useState(firstName);
  const [sLastName, setLastName] = useState(lastName);
  const [sBio, setBio] = useState(bio);
  const [sCoverPhotoUrl, setCoverPhotoUrl] = useState(coverPhotoUrl);
  const [sProfilePhotoUrl, setProfilePhotoUrl] = useState(profilePhotoUrl);
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const handleCoverPhotoPreview = () => { 
    dispatch(setProfileCoverPhotoUrl(sCoverPhotoUrl));
  };

  const handleProfilePhotoPreview = () => {
    dispatch(setProfileProfilePhotoUrl(sProfilePhotoUrl));
  }

  const formIsInvalid = () => {
    return false;
  }

  const handleSaveClick = async () => {
    setLoading(true);
    await delay(1000);
    const token = await getAuthToken(auth.currentUser!);
    return await axios.post(
      API_BASE_URL + '/api/profile',
      {
        firstName: sFirstName,
        lastName: sLastName,
        email: sEmail,
        bio: sBio,
        occupation: sOccupation,
        coverPhotoUrl: sCoverPhotoUrl,
        profilePhotoUrl: sProfilePhotoUrl
      },
      { headers: { 'Authorization': token, 'Content-Type': "application/json" } }
    )
      .then((response: AxiosResponse<IProfile>) => {
        dispatch(saveProfile(JSON.stringify(response.data)))
        toast({
          title: "Profile Updated",
          description: "Good for you!",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
        setLoading(false);
      })
      .catch((error: AxiosError) => {
        console.error(error);
        toast({
          title: "An error occurred.",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
        setLoading(false);
        return false;
      });
    return;
  }

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="10px"
        mb="4px"
      >
        Account Settings
      </Text>

      <Text color={textColorSecondary} fontSize="md" me="26px" mb="20px">
        Here you can change your account information. 
        <br />
        All fields are optional. Do not include personal or sensitive information if you do not wish this to be available on your public site.
        <br />
        This only affects what is shown on your page.
      </Text>
      <FormLabel
        display="flex"
        ms="4px"
        fontSize="sm"
        fontWeight="1000"
        color={textColorPrimary}
        mb="8px"
      >
        Cover Photo Url
      </FormLabel>
      <Flex>
        <Skeleton isLoaded={!loading} w='100%' mb="24px" borderRadius='15px'>
          <Input
            isRequired={false}
            variant="auth"
            fontSize="sm"
            type="url"
            placeholder="https://images.unsplash.com/photo-1620121692029-d088224ddc74?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
            fontWeight="500"
            disabled={loading}
            size="lg"
            value={sCoverPhotoUrl}
            onChange={(e) => setCoverPhotoUrl(e.target.value)}
          />
        </Skeleton>
        <Button
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="30%"
          ml="24px"
          disabled={coverPhotoPreviewIsLoading || loading}
          isLoading={coverPhotoPreviewIsLoading || loading}
          onClick={handleCoverPhotoPreview}
        >
          Preview
        </Button>
      </Flex>
      <FormLabel
        display="flex"
        ms="4px"
        fontSize="sm"
        fontWeight="1000"
        color={textColorPrimary}
        mb="8px"
      >
        Profile Photo Url
      </FormLabel>
      <Flex>
        <Skeleton isLoaded={!loading} w='100%' mb="24px" borderRadius='15px'>
          <Input
            isRequired={false}
            variant="auth"
            fontSize="sm"
            type="url"
            placeholder="https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"
            fontWeight="500"
            disabled={profilePhotoPreviewIsLoading || loading}
            size="lg"
            value={sProfilePhotoUrl}
            onChange={(e) => setProfilePhotoUrl(e.target.value)}
          />
        </Skeleton>
        <Button
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="30%"
          ml="24px"
          disabled={coverPhotoPreviewIsLoading || loading}
          isLoading={coverPhotoPreviewIsLoading || loading}
          onClick={handleProfilePhotoPreview}
        >
          Preview
        </Button>
      </Flex>
      <SimpleGrid columns={2} gap="20px">
        <FormControl>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="1000"
            color={textColorPrimary}
            mb="8px"
          >
            Email
          </FormLabel>
          <Skeleton isLoaded={!loading} mb='24px' borderRadius='15px'>
          <Input
            isRequired={false}
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="email"
            placeholder="mail@sty.re"
            fontWeight="500"
            disabled={loading}
            size="lg"
            value={sEmail}
            onChange={(e) => setEmail(e.target.value)}
            />
          </Skeleton>
        </FormControl>
        <FormControl>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="1000"
            color={textColorPrimary}
            mb="8px"
          >
            Occupation
          </FormLabel>
          <Skeleton isLoaded={!loading} mb='24px' borderRadius='15px'>
          <Input
            isRequired={false}
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="Web Developer"
            fontWeight="500"
            disabled={loading}
            size="lg"
            value={sOccupation}
            onChange={(e) => setOccupation(e.target.value)}
            />
          </Skeleton>
        </FormControl>
        <FormControl>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="1000"
            color={textColorPrimary}
            mb="8px"
          >
            First Name
          </FormLabel>
          <Skeleton isLoaded={!loading} mb='24px' borderRadius='15px'>
          <Input
            isRequired={false}
            variant="auth"
            fontSize="sm"
            type="text"
            placeholder="John"
            fontWeight="500"
            disabled={loading}
            size="lg"
            value={sFirstName}
            onChange={(e) => setFirstName(e.target.value)}
            />
            </Skeleton>
        </FormControl>
        <FormControl>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="1000"
            color={textColorPrimary}
            mb="8px"
          >
            Last Name
          </FormLabel>
          <Skeleton isLoaded={!loading} mb='24px' borderRadius='15px'>
          <Input
            isRequired={false}
            variant="auth"
            fontSize="sm"
            type="text"
            placeholder="Doe"
            fontWeight="500"
            disabled={loading}
            size="lg"
            value={sLastName}
            onChange={(e) => setLastName(e.target.value)}
            />
          </Skeleton>
        </FormControl>
      </SimpleGrid>
      <FormControl>
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="1000"
          color={textColorPrimary}
          mb="8px"
        >
          Bio
        </FormLabel>
        <Skeleton isLoaded={!loading} mb='24px' borderRadius='15px'>
        <Textarea
          isRequired={false}
          variant="auth"
          fontSize="sm"
          placeholder="Tell something about yourself in 150 characters!"
          fontWeight="500"
          disabled={loading}
          size="lg"
          value={sBio}
          onChange={(e) => setBio(e.target.value)}
          />
        </Skeleton>
      </FormControl>
      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        w="100%"
        h="50"
        disabled={formIsInvalid() || loading}
        isLoading={loading}
        onClick={handleSaveClick}
      >
        Save
      </Button>
    </Card>
  );
}
