import axios, { AxiosError, AxiosResponse } from 'axios';
import { API_BASE_URL } from 'const/api';
import { getAuth, deleteUser } from 'firebase/auth';
import { CheckValidHomePageNameResponse, MessageResponse, PublishOrRenewResponse } from 'types/api';
import { IHomePage } from 'types/homepage/homepage';
import { getAuthToken } from 'utils/auth/auth';
import { store } from '../../redux/store';

export const saveLinksForProfile = async (): Promise<{success: boolean, message: string}> => {
    const auth = getAuth();
    const token = await getAuthToken(auth.currentUser!);
    return await axios.post(API_BASE_URL + '/api/links', {
        sections: store.getState().profileLinks.sections,
        masterLinks: store.getState().profileLinks.masterLinks,
        links: store.getState().profileLinks.links
    }, {
        headers: {
            'Authorization': token, 'Content-Type': "application/json"
        }
    }
    ).then((response: AxiosResponse<MessageResponse>) => {
        return {
            success: true,
            message: response.data.message
        };
    }
    ).catch((err: AxiosError<MessageResponse>) => {
        return {
            success: false,
            message: err.response?.data.message || "Something went wrong"
        };
    });
}

export const checkHomePageUrl = async(name: string): Promise<{ valid: boolean, taken: boolean }> => {
    const auth = getAuth();
    const token = await getAuthToken(auth.currentUser!);
    return await axios.post(API_BASE_URL + '/api/check-site-url', {
        name
    }, {
        headers: {
            'Authorization': token, 'Content-Type': "application/json"
        }
    }
    ).then((response: AxiosResponse<CheckValidHomePageNameResponse>) => {
        return {
            valid: response.data.valid,
            taken: response.data.taken
        }
    }
    ).catch((err: AxiosError<MessageResponse>) => {
        return {
            valid: false,
            taken: false
        }
    });
}

export const publishOrRenewHomePage = async (name: string): Promise<PublishOrRenewResponse> => {
    const auth = getAuth();
    const token = await getAuthToken(auth.currentUser!);
    return await axios.post(API_BASE_URL + '/api/publish-or-renew-homepage', {
        name
    }, {
        headers: {
            'Authorization': token, 'Content-Type': "application/json"
        }
    }
    ).then((response: AxiosResponse<PublishOrRenewResponse>) => {
        return {
            expiration: response.data.expiration,
            success: response.data.success
        }
    }
    ).catch((err: AxiosError<MessageResponse>) => {
        return {
            expiration: null,
            success: false
        }
    });
}

export const unPublishHomePage = async (): Promise<{success: boolean}> => {
    const auth = getAuth();
    const token = await getAuthToken(auth.currentUser!);
    return await axios.delete(API_BASE_URL + '/api/homepage', {
        headers: {
            'Authorization': token, 'Content-Type': "application/json"
        }
    }
    ).then((response: AxiosResponse<{success: boolean}>) => {
        return {
            success: response.data.success
        }
    }
    ).catch((err: AxiosError<MessageResponse>) => {
        return {
            success: false
        }
    });
}

export const deleteProfileAndSignOut = async (): Promise<{success: boolean}> => {
    const auth = getAuth();
    const token = await getAuthToken(auth.currentUser!);
    return await axios.delete(API_BASE_URL + '/api/profile', {
        headers: {
            'Authorization': token, 'Content-Type': "application/json"
        }
    }
    ).then((response: AxiosResponse<{success: boolean}>) => {
        auth.signOut();
        return {
            success: true
        }
    }
    ).catch((err: AxiosError<MessageResponse>) => {
        return {
            success: false
        }
    });
}

export const getHomePageForUser = async (): Promise<IHomePage> => {
    const auth = getAuth();
    const token = await getAuthToken(auth.currentUser!);
    return await axios.get(API_BASE_URL + '/api/homepage', {
        headers: {
            'Authorization': token, 'Content-Type': "application/json"
        }
    }
    ).then((response: AxiosResponse<IHomePage>) => {
        return {
            expirationDate: response.data.expirationDate,
            name: response.data.name,
            isPublished: response.data.isPublished
        }
    }
    ).catch((err: AxiosError<any>) => {
        throw err;
    });
}

