import { Route } from "react-router-dom";

export const getRoute = () => {
  return window.location.pathname !== "/admin/full-screen-maps";
};

export const getActiveRoute = (routes: any): any => {
  let activeRoute = "Styre";
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].collapse) {
      let collapseActiveRoute = getActiveRoute(routes[i].items);
      if (collapseActiveRoute !== activeRoute) {
        return collapseActiveRoute;
      }
    } else if (routes[i].category) {
      let categoryActiveRoute = getActiveRoute(routes[i].items);
      if (categoryActiveRoute !== activeRoute) {
        return categoryActiveRoute;
      }
    } else {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
  }
  return activeRoute;
};
export const getActiveNavbar = (routes: any): any => {
  let activeNavbar = false;
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].collapse) {
      let collapseActiveNavbar = getActiveNavbar(routes[i].items);
      if (collapseActiveNavbar !== activeNavbar) {
        return collapseActiveNavbar;
      }
    } else if (routes[i].category) {
      let categoryActiveNavbar = getActiveNavbar(routes[i].items);
      if (categoryActiveNavbar !== activeNavbar) {
        return categoryActiveNavbar;
      }
    } else {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
  }
  return activeNavbar;
};
export const getActiveNavbarText = (routes: any): any => {
  let activeNavbar = false;
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].collapse) {
      let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
      if (collapseActiveNavbar !== activeNavbar) {
        return collapseActiveNavbar;
      }
    } else if (routes[i].category) {
      let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
      if (categoryActiveNavbar !== activeNavbar) {
        return categoryActiveNavbar;
      }
    } else {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].messageNavbar;
      }
    }
  }
  return activeNavbar;
};
export const getRoutes = (routes: any) => {
  return routes.map((prop: any, key: any) => {
    if (prop.layout === "/admin") {
      return <Route path={prop.path} element={prop.component} key={key} />;
    }
    if (prop.collapse) {
      return getRoutes(prop.items);
    }
    if (prop.category) {
      return getRoutes(prop.items);
    } else {
      return null;
    }
  });
};
