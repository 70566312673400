/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { Fragment, useEffect, useState } from "react";
import { NavLink, useNavigate} from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useInterval,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.webp";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  sendEmailVerification,
  GoogleAuthProvider,
  signInWithPopup,
  ProviderId,
} from "firebase/auth";
import { useToast } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/store";
import { updateUser } from "redux/slice/authSlice";

enum AuthState {
  LOGIN,
  SIGNUP,
  RESET,
  LOGGED_IN,
}

function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Firebase Auth
  const auth = getAuth();
  const [keepUserUpdated, setKeepUserUpdated] = useState(false);
  const userId = useSelector((state: IRootState) => state.auth.userId);
  const userEmailVerified = useSelector((state: IRootState) => state.auth.emailVerified);

  useInterval(
    async () => {
      if (auth.currentUser) {
        await auth.currentUser.reload();
      }
      dispatch(updateUser(null));
    },
    // Delay in milliseconds or null to stop it
    keepUserUpdated ? 5000 : null,
  )

  useEffect(() => {
    if (userId) {
      if(userEmailVerified) {
        navigate("/admin");
        return;
      }
      if(!keepUserUpdated) {
        setKeepUserUpdated(true);
        return;
      }
      if (!(authViewState === AuthState.LOGGED_IN)) {
        setAuthViewState(AuthState.LOGGED_IN);
        return;
      }
    }
    if (!userId) {
      if (keepUserUpdated) {
        setKeepUserUpdated(false);
        return;
      }
      if (authViewState === AuthState.LOGGED_IN) {
        setAuthViewState(AuthState.LOGIN);
        return;
      }
    }
  })

  const isLoggedIn = () => {
    return auth.currentUser !== null;
  };
  const isEmailVerified = () => {
    return auth.currentUser !== null && auth.currentUser.emailVerified;
  };

  // Chakra color mode
  const toast = useToast();
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClick = () => setShow(!show);
  const [authViewState, setAuthViewState] = useState(AuthState.LOGIN);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = () => {
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setLoading(false);
        setEmail("");
        setPassword("");
        toast({
          title: "Signed in.",
          description: "Welcome to Sty.re!",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      })
      .catch((error) => {
        setLoading(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error ocured: ", errorCode, errorMessage);
        toast({
          title: "An error occurred.",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      });
  };
  const handleSignup = () => {
    setLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setEmail("");
        setPassword("");
        setLoading(false);
        sendEmailVerification(userCredential.user);
        toast({
          title: "Account created.",
          description: "You must verify your email before you can sign in",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      })
      .catch((error) => {
        setLoading(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error ocured: ", errorCode, errorMessage);
        toast({
          title: "An error occurred.",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      });
  };
  const handleResetPassword = () => {
    setLoading(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setLoading(false);
        setAuthViewState(AuthState.LOGIN);
        toast({
          title: "Forgetful?",
          description: "We've sent you an email to reset your password.",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      })
      .catch((error) => {
        setLoading(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error ocured: ", errorCode, errorMessage);
        toast({
          title: "An error occurred.",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      });
  };

  const handleReSendEmailVerification = () => {
    setLoading(true);
    if (!auth.currentUser) {
      return;
    }
    sendEmailVerification(auth.currentUser)
      .then(() => {
        setLoading(false);
        toast({
          title: "A new verification e-mail has been sent.",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      })
      .catch((error) => {
        setLoading(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error ocured: ", errorCode, errorMessage);
        toast({
          title: "An error occurred.",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      });
  };

  const handleSignInGoogle = () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).then(() => {
      setLoading(false);
        toast({
          title: "Signed in.",
          description: "Welcome to Sty.re!",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
    }).catch((error) => {
        setLoading(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error ocured: ", errorCode, errorMessage);
        toast({
          title: "An error occurred.",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      });
  }

  const handleLogout = () => {
    auth.signOut();
  };
  const setAuthViewStateLogin = () => {
    setAuthViewState(AuthState.LOGIN);
  };
  const setAuthViewStateSignup = () => {
    setAuthViewState(AuthState.SIGNUP);
  };
  const setAuthViewStateResetPassword = () => {
    setAuthViewState(AuthState.RESET);
  };

  const stateHeader = () => {
    if (isLoggedIn() && !isEmailVerified()) {
      return "Verify Email";
    }
    switch (authViewState) {
      case AuthState.SIGNUP:
        return "Sign Up";
      case AuthState.RESET:
        return "Reset Password";
      default:
        return "Sign In";
    }
  };

  const stateDescription = () => {
    if (isLoggedIn() && !isEmailVerified()) {
      return "We have sent you an email.\nYou must click the link in the email to verify your account before you can sign in.";
    }
    switch (authViewState) {
      case AuthState.SIGNUP:
        return "Enter your email and password to sign up!";
      case AuthState.RESET:
        return "Enter your email to reset password!";
      default:
        return "Enter your email and password to sign in!";
    }
  };

  const getActiveButton = (): JSX.Element => {
    switch (authViewState) {
      case AuthState.SIGNUP:
        return (
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            mb="24px"
            onClick={handleSignup}
            isLoading={loading}
          >
            Sign Up
          </Button>
        );
      case AuthState.RESET:
        return (
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            mb="24px"
            isLoading={loading}
            onClick={handleResetPassword}
          >
            Reset Password
          </Button>
        );
      default:
        return (
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            mb="24px"
            isLoading={loading}
            onClick={handleLogin}
          >
            Sign In
          </Button>
        );
    }
  };

  let activeButton = getActiveButton();

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            {stateHeader()}
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            w={"350px"}
            color={textColorSecondary}
            fontWeight="400"
            fontSize="sm"
            whiteSpace={"pre-line"}
          >
            By creating an account or signing in, you confirm that you are over the age of 18, and that you will comply with our Terms of Use (behave nice and respectful).
          </Text>
          <Text
            mb="36px"
            ms="4px"
            w={"350px"}
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
            whiteSpace={"pre-line"}
          >
            {stateDescription()}
          </Text>
        </Box>
        {isLoggedIn() && !isEmailVerified() && (
          <Fragment>
            <Flex>
              <Spinner mr="20px" />{" "}
              <Text>We are awaiting your email verification...</Text>
            </Flex>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mt="24px"
              onClick={handleReSendEmailVerification}
              isLoading={loading}
            >
              Re-send verification email
            </Button>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mt="24px"
              onClick={handleLogout}
              isLoading={loading}
            >
              Log Out
            </Button>
          </Fragment>
        )}
        {!isLoggedIn() && (
          <Fragment>
            <Flex
              zIndex="2"
              direction="column"
              w={{ base: "100%", md: "420px" }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
            >
              <Button
                fontSize="sm"
                me="0px"
                mb="26px"
                py="15px"
                h="50px"
                borderRadius="16px"
                bg={googleBg}
                color={googleText}
                fontWeight="500"
                _hover={googleHover}
                _active={googleActive}
                _focus={googleActive}
                onClick={handleSignInGoogle}
                disabled={loading}
              >
                <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
                Sign in with Google
              </Button>
              <Flex align="center" mb="25px">
                <HSeparator />
                <Text color="gray.400" mx="14px">
                  or
                </Text>
                <HSeparator />
              </Flex>
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Email<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="email"
                  placeholder="mail@sty.re"
                  mb="24px"
                  fontWeight="500"
                  disabled={loading}
                  size="lg"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {[AuthState.LOGIN, AuthState.SIGNUP].includes(
                  authViewState
                ) && (
                  <Fragment>
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Password<Text color={brandStars}>*</Text>
                    </FormLabel>

                    <InputGroup size="md">
                      <Input
                        isRequired={true}
                        fontSize="sm"
                        disabled={loading}
                        placeholder="Min. 8 characters"
                        mb="24px"
                        size="lg"
                        type={show ? "text" : "password"}
                        variant="auth"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <InputRightElement
                        display="flex"
                        alignItems="center"
                        mt="4px"
                      >
                        <Icon
                          color={textColorSecondary}
                          _hover={{ cursor: "pointer" }}
                          as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                          onClick={handleClick}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <Flex justifyContent="end" align="right" w="100%" mb="24px">
                      {[AuthState.LOGIN, AuthState.RESET].includes(
                        authViewState
                      ) && (
                        <Text
                          color={textColorBrand}
                          cursor="pointer"
                          align="end"
                          fontSize="sm"
                          w="164px"
                          fontWeight="500"
                          onClick={setAuthViewStateResetPassword}
                        >
                          Forgot password?
                        </Text>
                      )}
                    </Flex>
                  </Fragment>
                )}
                {activeButton}
              </FormControl>
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="start"
                maxW="100%"
                mt="0px"
              >
                {[AuthState.LOGIN].includes(authViewState) && (
                  <Text
                    color={textColorDetails}
                    fontWeight="400"
                    fontSize="14px"
                  >
                    Not registered yet?
                    <Text
                      cursor={"pointer"}
                      color={textColorBrand}
                      as="span"
                      ms="5px"
                      fontWeight="500"
                      onClick={setAuthViewStateSignup}
                    >
                      Create an Account
                    </Text>
                  </Text>
                )}
                {[AuthState.SIGNUP, AuthState.RESET].includes(
                  authViewState
                ) && (
                  <Text
                    color={textColorDetails}
                    fontWeight="400"
                    fontSize="14px"
                  >
                    Already have an account?
                    <Text
                      cursor={"pointer"}
                      color={textColorBrand}
                      as="span"
                      ms="5px"
                      fontWeight="500"
                      onClick={setAuthViewStateLogin}
                    >
                      Sign in
                    </Text>
                  </Text>
                )}
              </Flex>
            </Flex>
          </Fragment>
        )}
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
