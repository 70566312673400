export interface ISection {
    _id: string | null;
    index?: number;
    title: string;
    weakId: string;
    masterLinkWeakIds: string[];
    editingMasterLinkWeakIds: string[];
}

export const createEmptySection = (weakId: string): ISection => {
    return {
        _id: null,
        title: "Section Title",
        weakId: weakId,
        masterLinkWeakIds: [],
        editingMasterLinkWeakIds: []
    };
}