import { Text, Box, useColorMode, Link, Flex } from "@chakra-ui/react";
import Select, {
  components,
  ControlProps,
  OptionProps,
  SingleValueProps,
} from "react-select";
import { globalStyles } from "theme/styles";
import { useDispatch } from "react-redux";
import { mode } from "@chakra-ui/theme-tools";
import { useState } from "react";
import * as allIcons from "react-icons/fa";
import AsyncSelect from "react-select/async";
import { SocialIcon } from "react-social-icons";
import { setCustomIcon } from "redux/slice/profileLinksSlice";

interface IconSelectProps {
  weakId: string;
}

export default function IconSelect(props: IconSelectProps) {
  const dispatch = useDispatch();
  const { colorMode } = useColorMode();
  const [currentIcon, setCurrentIcon] = useState(null);
  const [currentIconName, setCurrentIconName] = useState("Default");
  const [search, setSearch] = useState("");

  interface CustomOptionProps extends OptionProps {
    data: {
      label: string;
      value: any;
    };
  }

  interface CustomOptionType {
    label: string;
    value: any;
  }

  const onIconChanged = (data: CustomOptionType) => {
    if (data === null) {
      setCurrentIcon(null);
      setCurrentIconName("Default");
      dispatch(
        setCustomIcon({
          id: props.weakId,
          value: null,
        })
      );
    } else {
      setCurrentIcon(data.value);
      setCurrentIconName(data.label);
      dispatch(
        setCustomIcon({
          id: props.weakId,
          value: data.label,
        })
      );
    }
    return;
  };

  const CustomOption = ({
    innerProps,
    isDisabled,
    data,
  }: CustomOptionProps) => {
    return !isDisabled ? (
      <Box
        {...innerProps}
        display="flex"
        alignItems="center"
        h="40px"
        _hover={{
          bgColor: mode("#FFFFFF60!important", "whiteAlpha.200!important"),
        }}
      >
        <Box w="50px" display="flex" justifyContent="center">
          {data.value()}
        </Box>
        <Text>{data.label}</Text>
      </Box>
    ) : null;
  };

  const SingleValue = ({ children, ...props }: SingleValueProps) => {
    props.innerProps = {};
    props.innerProps.style = {
      ...props.innerProps.style,
      color: colorMode === "dark" ? "white" : "black",
    };
    return (
      <components.SingleValue {...props}>
        {currentIconName}
        {children}
      </components.SingleValue>
    );
  };

  const Control = ({ children, ...props }: ControlProps) => {
    const bgColor =
      colorMode === "dark" ? globalStyles.colors.navy[900] : "white";
    props.innerProps.style = {
      ...props.innerProps.style,
      backgroundColor: bgColor,
    };
    return (
      <components.Control {...props}>
        <Box cursor={"pointer"} display="inherit" w={"100%"}>
          <Box ml={"10px"} display="flex" alignItems={"center"}>
            {currentIcon === null ? (
              <SocialIcon
                network={undefined}
                style={{ height: "25px", width: "25px" }}
                bgColor={colorMode === "dark" ? "white" : ""}
              />
            ) : (
              currentIcon
            )}
          </Box>
          {children}
        </Box>
      </components.Control>
    );
  };

  const MenuList = (props: any) => {
    return (
      <Box bg={colorMode === "dark" ? "navy.900" : "secondaryGray.300"}>
        <components.MenuList {...props}>{props.children}</components.MenuList>
      </Box>
    );
  };

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue;
    return inputValue;
  };

  const filterIcons = (inputValue: string) => {
    return Object.entries(allIcons)
      .filter((ic) => {
        return ic[0].toLowerCase().includes(inputValue.toLowerCase());
      })
      .slice(0, 10)
      .map((ic) => {
        return {
          label: ic[0],
          value: ic[1],
        };
      });
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: CustomOptionType[]) => void
  ) => {
    setTimeout(() => {
      callback(filterIcons(inputValue));
    }, 1000);
  };

  const CustomLinkSelect = (props: any) => (
    <AsyncSelect
      {...props}
      isClearable
      loadOptions={loadOptions}
      defaultOptions
      onInputChange={handleInputChange}
      components={{ Control, Option: CustomOption, MenuList, SingleValue }}
    />
  );

  return (
    <Flex w={"100%"} justifyContent="end">
      <Box w={'100%'}>
        <CustomLinkSelect
          className="icon-select"
          placeholder="Icon (Search)"
          onChange={onIconChanged}
          value={currentIcon}
        />
          <Link
            color={"blue"}
            ml='5px'
            href="https://react-icons.github.io/react-icons/icons?name=fa"
          >
            View all icons
          </Link>
      </Box>
    </Flex>
  );
}
