/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { AddIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Divider,
  Flex,
  FormLabel,
  Icon,
  Text,
  Portal,
  Select,
  SimpleGrid,
  useColorModeValue,
  useDisclosure,
  Button,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import Navbar from "components/navbar/NavbarAdmin";
import { useState } from "react";
import routes from "routes";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getActiveNavbar, getActiveNavbarText, getActiveRoute, getRoutes } from "utils/navigation/navbar";
import Section from "./components/Section";
import { v4 as uuidv4 } from 'uuid';
import { IRootState } from "redux/store";
import { addNewSection, removeSection, setDraftMode, setSaveLoading } from "redux/slice/profileLinksSlice";
import axios from "axios";
import { saveLinksForProfile } from "utils/api/apiConnector";
import { delay } from "lodash";

export default function Links(props: any) {
  const dispatch = useDispatch();
  const sectionWeakIds: string[] = useSelector((state: IRootState) => state.profileLinks.sections.allWeakIds);
  const draftMode = useSelector((state: IRootState) => state.profileLinks.isDraftMode);
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  const { onOpen } = useDisclosure();
  const toast = useToast();
  const { ...rest } = props;

  // states and functions
  const [fixed] = useState(false);
  const saveLoading = useSelector((state: IRootState) => state.profileLinks.saveLoading);

  const addSection = () => {
    dispatch(addNewSection(null));
    dispatch(setDraftMode(true));
  }

  const deleteSection = (weakId: string) => {
    dispatch(removeSection(weakId));
    dispatch(setDraftMode(true));
  }

  const saveChanges = async () => {
    dispatch(setSaveLoading(true));
    dispatch(setDraftMode(false));
    const res = await saveLinksForProfile();
    await new Promise(resolve => setTimeout(resolve, 1000));
    dispatch(setSaveLoading(false));
    if (!res.success) {
      dispatch(setDraftMode(true));
    }
    toast({
      title: res.success ? "Changes saved" : "Ooops",
      description: res.success ? "Your links are now up to date 🙌" : <Box whiteSpace={'pre-line'}>{res.message}</Box>,
      status: res.success ? "success" : "error",
      duration: 9000,
      isClosable: true,
      position: "top-right",
    });
  }


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Portal>
        <Box>
          <Navbar
            onOpen={onOpen}
            logoText={"Styre"}
            brandText={getActiveRoute(routes)}
            secondary={getActiveNavbar(routes)}
            message={getActiveNavbarText(routes)}
            fixed={fixed}
            {...rest}
          />
        </Box>
      </Portal>

      <Box w='100%' display='flex' justifyContent='end' mt='20px' mb="24px" position={'relative'} flexDirection={{base: 'column', xl: 'row'}}>
      <Flex opacity={draftMode ? 1 : 0} transition='1s ease all' justifyContent={{base: 'end', xl: 'none'}} mb={{base: '20px', xl: '0'}}>
          <Flex alignItems={'center'} mr='10px' >
            <Text fontSize={'xl'}>
              Remember to save your changes ➡️
            </Text>
          </Flex>
          <Button
            fontSize="sm"
            variant="success"
            fontWeight="500"
            h="50"
            mr='10px'
            onClick={saveChanges}
            disabled={!draftMode}
            cursor={!draftMode ? 'default!important' : 'pointer'}
          >
            Save Changes
          </Button>
        </Flex>
        <Flex display={sectionWeakIds.length == 0 && !draftMode ? 'flex' : 'none'} transition='1s ease all'>
          <Flex alignItems={'center'} mr='10px' >
            <Text fontSize={'xl'}>
              You have not yet added any links, get started here ➡️
            </Text>
          </Flex>
        </Flex>
        <Button
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          h="50"
          mt={{ base: "20px", xl: "0px"}}
          onClick={addSection}
          disabled={sectionWeakIds.length >= 4}
        >
          {sectionWeakIds.length >= 4 ? 'Maximum sections reached' : 'New Section'}
        </Button>
      </Box>

      <Box position='relative' display={'block'}>
        <Box>
          {sectionWeakIds.map((wid) => { return (<Section key={wid} weakId={wid} deleteSection={deleteSection}></Section>) })}
        </Box>
        <Flex background={'white'} position='absolute' top='0' bottom='0' left='0' right='0' zIndex={saveLoading ? 9 : -1} borderRadius='20px' opacity={saveLoading ? '1' : '0'} transition={'1s ease all'} pointerEvents='none' justifyContent='center' alignItems='center'>
          <Spinner size={'xl'} />
        </Flex>
      </Box>

    </Box>
  );
}
