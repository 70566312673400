import {
  Text,
  Box,
  useColorMode,
} from "@chakra-ui/react";
import Select, {
  components,
  ControlProps,
  OptionProps,
  SingleValueProps,
} from "react-select";
import { globalStyles } from "theme/styles";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/store";
import { LinkType, OTHER_LINK_TYPE } from "types/links/linkType";
import { mode } from "@chakra-ui/theme-tools";
import { updateMasterLinkType } from "redux/slice/profileLinksSlice";
import LinkIcon from "./LinkIcon";

interface LinkSelectProps {
  weakId: string;
}

export default function LinkSelect(props: LinkSelectProps) {
  const dispatch = useDispatch();
  const { colorMode } = useColorMode();
  const linkTypes = useSelector(
    (state: IRootState) => state.profileLinks.linkTypes
  );
  const currentLinkTypeId = useSelector(
    (state: IRootState) => state.profileLinks.masterLinks.byWeakId[props.weakId].type
  );
  const fullCurrentlinkType =
    useSelector((state: IRootState) => state.profileLinks.linkTypes).find(
      (o) => o.id === currentLinkTypeId
    ) || OTHER_LINK_TYPE;

  const getLinkTypeOptions = () => {
    return linkTypes.map((lt) => {
      return {
        label: lt.label,
        value: lt,
      };
    });
  };

  interface CustomOptionProps extends OptionProps {
    data: {
      label: string;
      value: LinkType;
    };
  }

  interface CustomOptionType {
    label: string;
    value: LinkType;
  }

  const onLinkTypeChanged = (data: CustomOptionType) => {
    dispatch(
      updateMasterLinkType({
        masterLinkWeakId: props.weakId,
        newType: data.value.id,
      })
    );
    return;
  };

  const CustomOption = ({
    innerProps,
    isDisabled,
    data,
  }: CustomOptionProps) => {
    return !isDisabled ? (
      <Box
        {...innerProps}
        display="flex"
        alignItems="center"
        h="40px"
        _hover={{
          bgColor: mode("#FFFFFF60!important", "whiteAlpha.200!important"),
        }}
      >
        <Box w="50px" display="flex" justifyContent="center">
          <LinkIcon typeId={data.value.id} />
        </Box>
        <Text>{data.label}</Text>
      </Box>
    ) : null;
  };

  const SingleValue = ({ children, ...props }: SingleValueProps) => {
    props.innerProps = {};
    props.innerProps.style = {
      ...props.innerProps.style,
      color: colorMode === "dark" ? "white" : "black",
    };
    return (
      <components.SingleValue {...props}>{children}</components.SingleValue>
    );
  };

  const Control = ({ children, ...props }: ControlProps) => {
    const bgColor =
      colorMode === "dark" ? globalStyles.colors.navy[900] : "white";
    props.innerProps.style = {
      ...props.innerProps.style,
      backgroundColor: bgColor,
    };
    return (
      <components.Control {...props}>
        <Box cursor={"pointer"} display="inherit" w={"100%"}>
          <Box ml={"10px"} display="flex" alignItems={"center"}>
            <LinkIcon typeId={currentLinkTypeId} />
          </Box>{" "}
          {children}
        </Box>
      </components.Control>
    );
  };

  const MenuList = (props: any) => {
    return (
      <Box bg={colorMode === "dark" ? "navy.900" : "secondaryGray.300"}>
        <components.MenuList {...props}>{props.children}</components.MenuList>
      </Box>
    );
  };

  const CustomLinkSelect = (props: any) => (
    <Select
      {...props}
      components={{ Control, Option: CustomOption, MenuList, SingleValue }}
    />
  );

  return (
    <CustomLinkSelect 
    className="link-select"
    placeholder="Link Type"
    options={getLinkTypeOptions()}
    onChange={onLinkTypeChanged}
    value={fullCurrentlinkType} />
  );
}
