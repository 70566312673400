// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import Card from "components/card/Card";
import React from "react";
import { useSelector } from "react-redux";
import { IRootState } from "redux/store";
import theme from "theme/theme";
import defaultBanner from "assets/img/auth/banner.png";
import { EmailIcon } from "@chakra-ui/icons";

export default function Banner(props: any) {
  const { banner, avatar, name, job, bio, email } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  function getBanner(): string {
    return `url(${banner || defaultBanner})`;
  }

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
      <Box
        bg={getBanner()}
        bgSize='cover'
        borderRadius='16px'
        bgPosition='center'
        h='131px'
        w='100%'
      />
      <Avatar
        mx='auto'
        src={avatar}
        h='87px'
        w='87px'
        mt='-43px'
        border='4px solid'
        borderColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
        {name}
      </Text>
      <Text color={textColorSecondary} fontSize='sm' mb='10px'>
        {job}
      </Text>
      {email && 
        <Box display='flex' justifyContent='center' alignItems='center'>
          <EmailIcon color={textColorSecondary} mr='3px' /> <Text color={textColorSecondary} fontSize='sm'>{email}</Text>
        </Box>
      }
      <Flex w='max-content' mx='auto' mt='20px'>
        <Flex mx='auto' align='center' direction='column' justifyContent='center'>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            { bio }
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
