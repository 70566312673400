import React from "react";

// Chakra imports
import { Flex, useColorModeValue, Text } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align="center" direction="column">
      <Flex justify="center" align="center">
        <Text
        fontSize='32px'
        fontWeight='1000'
        fontFamily='DM sans'
        >Sty.</Text>
        <Text
        fontSize='32px'
        >re</Text>
      </Flex>
      <HSeparator mb="20px" mt='20px' />
    </Flex>
  );
}

export default SidebarBrand;
