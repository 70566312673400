import { Box, Heading, List, ListItem, Text } from "@chakra-ui/react";

interface PrivacyProps {}

export default function Privacy(props: PrivacyProps) {
  return (
    <Box p='50px'>
      <Heading mb={'10px'}>Privacy Policy for Sty.re</Heading>

      <Text>We DO NOT sell your data.</Text>

      <Text>
        One of our main priorities is the
        privacy of our visitors. This Privacy Policy document contains types of
        information that is collected and recorded by Sty.re and how we use it.
      </Text>

      <Text>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us.
      </Text>

      <Text>
        This Privacy Policy applies only to our online activities and is valid
        for visitors to our website with regards to the information that they
        shared and/or collect in Sty.re. This policy is not applicable to any
        information collected offline or via channels other than this website.
        Our Privacy Policy was created with the help of the{" "}
        <a href="https://www.privacypolicygenerator.info/">
          Free Privacy Policy Generator
        </a>
        .
      </Text>

      <Heading mt={'20px'} mb={'10px'}>Consent</Heading>

      <Text>
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </Text>

      <Heading mt={'20px'} mb={'10px'}>Information we collect</Heading>

      <Text>
        You may provide personal information to be displayed on your profile. 
        Note that this is completely optional, and you may choose to not provide any personal information there.

        You may choose to sign up using either Google or Email. This information is stored by Google / Firebase. 
        This information is required to be able to log in and use Sty.re.

        You may provide additional information to store links to be displayed on your profile. 
        Note that this is also completely optional. 
        This information is stored on MongoDB Atlas. 
      </Text>
      <Text>
        If you contact us directly, we may receive additional information about
        you such as your name, email address, phone number, the contents of the
        message and/or attachments you may send us, and any other information
        you may choose to provide.
      </Text>
      <Text>
        Sty.re uses Firebase (Google) Analytics to collect data about usage of the website. You can read more about how Google processes this data on https://policies.google.com/technologies/partner-sites.
      </Text>

      <Heading mt={'20px'} mb={'10px'}>How we use your information</Heading>

      <Text>We use the information we collect in various ways, including to:</Text>

      <List>
        <ListItem>- Provide, operate, and maintain our website</ListItem>
        <ListItem>- Improve, personalize, and expand our website</ListItem>
        <ListItem>- Understand and analyze how you use our website</ListItem>
        <ListItem>- Develop new products, services, features, and functionality</ListItem>
        <ListItem>
          - Communicate with you, either directly or through one of our partners,
          including for customer service, to provide you with updates and other
          information relating to the website, and for marketing and promotional
          purposes
        </ListItem>
        <ListItem>- Send you emails</ListItem>
        <ListItem>- Find and prevent fraud</ListItem>
      </List>

      <Heading mt={'20px'} mb={'10px'}>Log Files</Heading>

      <Text>
        Sty.re follows a standard procedure of using log files. These files log
        visitors when they visit websites. All hosting companies do this and a
        part of hosting services' analytics. The information collected by log
        files include internet protocol (IP) addresses, browser type, Internet
        Service Provider (ISP), date and time stamp, referring/exit pages, and
        possibly the number of clicks. These are not linked to any information
        that is personally identifiable. The purpose of the information is for
        analyzing trends, administering the site, tracking users' movement on
        the website, and gathering demographic information.
      </Text>

      <Heading mt={'20px'} mb={'10px'}>Cookies and Web Beacons</Heading>

      <Text>
        Like any other website, Sty.re uses 'cookies'. These cookies are used to
        store information including visitors' preferences, and the pages on the
        website that the visitor accessed or visited. The information is used to
        optimize the users' experience by customizing our web page content based
        on visitors' browser type and/or other information. 
        If you do not want to allow the use of cookies, you can ensure this by changing the settings in the browser or on your device.
      </Text>

      <Heading mt={'20px'} mb={'10px'}>Advertising Partners Privacy Policies</Heading>

      <Text>
        You may consult this list to find the Privacy Policy for each of the
        advertising partners of Sty.re.
      </Text>

      <Text>
        Third-party ad servers or ad networks uses technologies like cookies,
        JavaScript, or Web Beacons that are used in their respective
        advertisements and links that appear on Sty.re, which are sent directly
        to users' browser. They automatically receive your IP address when this
        occurs. These technologies are used to measure the effectiveness of
        their advertising campaigns and/or to personalize the advertising
        content that you see on websites that you visit.
      </Text>

      <Text>
        Note that Sty.re has no access to or control over these cookies that are
        used by third-party advertisers.
      </Text>

      <Heading mt={'20px'} mb={'10px'}>Third Party Privacy Policies</Heading>

      <Text>
        Sty.re's Privacy Policy does not apply to other advertisers or websites.
        Thus, we are advising you to consult the respective Privacy Policies of
        these third-party ad servers for more detailed information. It may
        include their practices and instructions about how to opt-out of certain
        options.{" "}
      </Text>

      <Text>
        You can choose to disable cookies through your individual browser
        options. To know more detailed information about cookie management with
        specific web browsers, it can be found at the browsers' respective
        websites.
      </Text>

      <Heading mt={'20px'} mb={'10px'}>GDPR Data Protection Rights</Heading>

      <Text>
        We would like to make sure you are fully aware of all of your data
        protection rights. Every user is entitled to the following:
      </Text>
      <Text>
        The right to access – You have the right to request copies of your
        personal data. You can view this simply by logging in and viewing your profile, and your links.
      </Text>
      <Text>
        The right to rectification – You have the right to request that we
        correct any information you believe is inaccurate. You also have the
        right to request that we complete the information you believe is
        incomplete. If you find that information you have entered is inaccurate, you may simply update your profile to amend this.
      </Text>
      <Text>
        The right to erasure – You have the right to request that we erase your
        personal data, under certain conditions. You can do this yourself, simply by logging in and deleting your account.
      </Text>
      <Text>
        The right to restrict processing – You have the right to request that we
        restrict the processing of your personal data, under certain conditions. You can do this yourself, simply by logging in and deleting your account.
      </Text>
      <Text>
        The right to object to processing – You have the right to object to our
        processing of your personal data, under certain conditions. You can do this yourself, simply by logging in and deleting your account.
      </Text>
      <Text>
        The right to data portability – You have the right to request that we
        transfer the data that we have collected to another organization, or
        directly to you, under certain conditions.
      </Text>
      <Text>
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us on simonolsen1994@gmail.com.
      </Text>

      <Heading mt={'20px'} mb={'10px'}>External Sites</Heading>
      <Text>
        Users may share links on Sty.re to sites that are not operated by us. By clicking on these, you will be directed to that site. We advice that you get familiar with the Privacy Policy and Terms of Use for these sites when you use them. 
        Sty.re has no control or responsibility for the content or practices used by any third party site. 
        As part of our Terms of Use, you will find that links to sites of certain types or bad taste (violence, adult content, hateful speech, criminal content, etc.) are prohibited. 
        However, this may be difficult to enforce, and we ask that if you should encounter a violation of this, that you contact us @ simonolsen1994@gmail.com, so that we may take action accordingly.
      </Text>

      <Heading mt={'20px'} mb={'10px'}>Children's Information</Heading>

      <Text>
        Another part of our priority is adding protection for children while
        using the internet. We encourage parents and guardians to observe,
        participate in, and/or monitor and guide their online activity.
      </Text>

      <Text>
        Sty.re does not knowingly collect any Personal Identifiable Information
        from children under the age of 18. If you think that your child provided
        this kind of information on our website, we strongly encourage you to
        contact us immediately and we will do our best efforts to promptly
        remove such information from our records.
      </Text>

      <Heading mt={'20px'} mb={'10px'}>Changes to Privacy Policy</Heading>
      <Text>
        We try to not change this policy often, but from time to time, we may need to. We ask that you check in with this policy frequently. 
        By continuing to use the site after any changes, you accept the current policy.
      </Text>
    </Box>
  );
}
