import React, {  } from "react";
import { Box, useColorModeValue, FormLabel, Input, InputGroup, Flex, InputLeftAddon } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Selector } from "@reduxjs/toolkit";
import { IRootState } from "redux/store";


interface LinkValueFieldProps {
    weakId: string,
    label: string,
    selector: Selector,
    onChange: (id: string, value: string) => void,
    placeholder: string
    isTitle: boolean
}

export default function LinkValueField(props: LinkValueFieldProps) {
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const fieldValue = useSelector(props.selector) as string;
    const prepend = useSelector((state: IRootState) => props.isTitle ? null : state.profileLinks.links.byWeakId[props.weakId].prepend);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(props.weakId, e.target.value);
    }

    return (
        <Box>
            <Flex w='100%' justifyContent={'end'}>
            <FormLabel
                display="block"
                ms="4px"
                fontSize="sm"
                fontWeight="1000"
                color={textColorPrimary}
                mb="8px"
            >
                {props.label}
                </FormLabel>
            </Flex>
            <InputGroup>
                {!props.isTitle && prepend && 
                    <InputLeftAddon children={prepend} h='32px' fontWeight={'200'} fontFamily='DM Sans' fontSize={'14px'} />
                }
                <Input
                    isRequired={true}
                    isInvalid={fieldValue.length < 1}
                    errorBorderColor='red.300'
                    borderRadius='10px'
                    fontSize="sm"
                    type="url"
                    placeholder={props.placeholder}
                    fontWeight="200"
                    size="sm"
                    value={fieldValue}
                    onChange={onChange}
                />
            </InputGroup>
        </Box>
    );
}
