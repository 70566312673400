import React, { } from "react";
import { Box, useColorModeValue, FormLabel, Input, InputGroup, Button } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Selector } from "@reduxjs/toolkit";
import { IRootState } from "redux/store";
import { isValidLinks, isValidMasterLink, isValidMasterLinkByWid, isValidPrimaryLink } from "utils/linkValidation";

interface ValidCheckButtonProps {
    masterLinkWeakId: string,
    onClick: () => void;
}

export default function ValidCheckButton(props: ValidCheckButtonProps) {
    const primaryLink = useSelector((state: IRootState) => state.profileLinks.links
        .byWeakId[state.profileLinks.masterLinks.byWeakId[props.masterLinkWeakId]
            .primaryLinkWeakId]);
    const links = useSelector((state: IRootState) => state.profileLinks.masterLinks.byWeakId[props.masterLinkWeakId].linkWeakIds.map(lWid => state.profileLinks.links.byWeakId[lWid]));

    const isValidPL: boolean = isValidPrimaryLink(primaryLink);
    const isValidLs = isValidLinks(links);
    return (
        <Box display='flex' justifyContent='end' mt='24px'>
            <Button
                fontSize="sm"
                variant="brand"
                size='sm'
                fontWeight="500"
                h="40px"
                disabled={!isValidPL || !isValidLs}
                onClick={props.onClick}
            >
                Done
            </Button>
        </Box>
    );
}
