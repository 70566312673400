import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { remove } from "lodash";
import { store } from "redux/store";
import { LinkDataResponse } from "types/api";
import {  createEmptyMasterLink, IProfileLinks, IMasterLink, ILink, createEmptyLink } from "types/links/links";
import { LinkType, OTHER_LINK_TYPE } from "types/links/linkType";
import { createEmptySection, ISection } from "types/links/section";
import { IProfile } from "types/profile/profile";
import { NormalizedObjects } from "types/redux/generic";
import { AddMasterLinkPayload, IdNullableValuePair, IdValuePair, LinkAdditionPayload, LinkRemovalPayload, LinkUpdatePayload,  MasterLinkRemovalPayload, MasterLinkUpdatePayload, MasterLinkUpdateTypePayload, StringArrayUpdatePayload,  } from "types/redux/payloadTypes";
import { v4 as uuidv4 } from "uuid";


interface IProfileLinksState {
  sections: NormalizedObjects<ISection>,
  masterLinks: NormalizedObjects<IMasterLink>,
  links: NormalizedObjects<ILink>,
  isDraftMode: boolean,
  saveLoading: boolean
  linkTypes: LinkType[]
}

const initialState: IProfileLinksState = {
  linkTypes: [],
  sections: {
    byWeakId: {},
    allWeakIds: []
  },
  masterLinks: {
    byWeakId: {},
    allWeakIds: []
  },
  links: {
    byWeakId: {},
    allWeakIds: []
  },
  isDraftMode: false,
  saveLoading: false
};

export const profileLinksSlice = createSlice({
  name: "profileLinks",
  initialState,
  reducers: {
    saveLinkTypes: (state, action) => {
      state.linkTypes = action.payload;
    },
    addNewSection: (state, _) => {
      const newSection: ISection = createEmptySection(uuidv4());
      state.sections.allWeakIds.push(newSection.weakId);
      state.sections.byWeakId[newSection.weakId] = newSection;
    },
    removeSection: (state, action) => {
      state.sections.allWeakIds = state.sections.allWeakIds.filter(o => o !== action.payload);
      // Remove any child masterlinks from 'allWeakIds'
      state.masterLinks.allWeakIds = state.masterLinks.allWeakIds.filter(o => !state.sections.byWeakId[action.payload].masterLinkWeakIds.includes(o));
      // Remove any child masterlinks from 'byWeakId'
      state.sections.byWeakId[action.payload].masterLinkWeakIds.forEach(mlWid => {
        // Remove any link children of the masterlink from allWeakIds before we delete it
        state.links.allWeakIds = state.links.allWeakIds.filter(o => o !== state.masterLinks.byWeakId[mlWid].primaryLinkWeakId);
        state.links.allWeakIds = state.links.allWeakIds.filter(o => !state.masterLinks.byWeakId[mlWid].linkWeakIds.includes(o));
        // Remove any link childern from byWeakId
        delete state.links.byWeakId[state.masterLinks.byWeakId[mlWid].primaryLinkWeakId];
        state.masterLinks.byWeakId[mlWid].linkWeakIds.forEach(lWid => {
          delete state.links.byWeakId[lWid];
        });
        delete state.masterLinks.byWeakId[mlWid];
      });
      delete state.sections.byWeakId[action.payload];
    },
    updateSectionTitle: (state, action: PayloadAction<IdValuePair>) => {
      state.sections.byWeakId[action.payload.id].title = action.payload.value;
    },
    addMasterLink: (state, action: PayloadAction<AddMasterLinkPayload>) => {
      const masterLinkWeakId = action.payload.masterLinkId ? action.payload.masterLinkId : uuidv4();
      const newPrimaryLink = createEmptyLink(uuidv4(), masterLinkWeakId, OTHER_LINK_TYPE.id);
      state.links.allWeakIds.push(newPrimaryLink.weakId);
      state.links.byWeakId[newPrimaryLink.weakId] = newPrimaryLink;
      const newMasterLink = createEmptyMasterLink(masterLinkWeakId, action.payload.sectionWeakId, newPrimaryLink.weakId);
      state.sections.byWeakId[newMasterLink.sectionWeakId].masterLinkWeakIds.push(newMasterLink.weakId);
      state.masterLinks.allWeakIds.push(newMasterLink.weakId);
      state.masterLinks.byWeakId[newMasterLink.weakId] = newMasterLink;
    },
    removeMasterLink: (state, action: PayloadAction<MasterLinkRemovalPayload>) => {
      const ml = state.masterLinks.byWeakId[action.payload.masterLinkWeakId];
      // Delete from section (which is tracking this one)
      let sectionTrackingMasterIds = state.sections.byWeakId[state.masterLinks.byWeakId[action.payload.masterLinkWeakId].sectionWeakId].masterLinkWeakIds;
      state.sections.byWeakId[state.masterLinks.byWeakId[action.payload.masterLinkWeakId].sectionWeakId].masterLinkWeakIds = sectionTrackingMasterIds.filter(o => o !== action.payload.masterLinkWeakId);
      // Delete any child links
      state.links.allWeakIds = state.links.allWeakIds.filter(o => o !== ml.primaryLinkWeakId);
      state.links.allWeakIds = state.links.allWeakIds.filter(o => !ml.linkWeakIds.includes(o));
      delete state.links.byWeakId[ml.primaryLinkWeakId];
      ml.linkWeakIds.forEach(lWid => {
        delete state.links.byWeakId[lWid];
      });
      state.masterLinks.allWeakIds = state.masterLinks.allWeakIds.filter(o => o !== action.payload.masterLinkWeakId);
      delete state.masterLinks.byWeakId[action.payload.masterLinkWeakId];
    },
    updateMasterLinkType: (state, action: PayloadAction<MasterLinkUpdateTypePayload>) => {
      state.masterLinks.byWeakId[action.payload.masterLinkWeakId].type = action.payload.newType;
      state.links.byWeakId[state.masterLinks.byWeakId[action.payload.masterLinkWeakId].primaryLinkWeakId].prepend = state.linkTypes.find(l => l.id === action.payload.newType)?.basePath || '';
    },
    updateMasterLinkTitle: (state, action: PayloadAction<IdValuePair>) => {
      state.masterLinks.byWeakId[action.payload.id].title = action.payload.value;
    },
    addEmptyLink: (state, action: PayloadAction<LinkAdditionPayload>) => {
      const newLink = createEmptyLink(uuidv4(), action.payload.masterLinkWeakId, action.payload.linkType);
      state.masterLinks.byWeakId[action.payload.masterLinkWeakId].linkWeakIds.push(newLink.weakId);
      state.links.allWeakIds.push(newLink.weakId);
      state.links.byWeakId[newLink.weakId] = newLink;
    },
    removeLink: (state, action: PayloadAction<LinkRemovalPayload>) => {
      state.masterLinks.byWeakId[state.links.byWeakId[action.payload.linkWeakId].masterLinkWeakId].linkWeakIds = state.masterLinks.byWeakId[state.links.byWeakId[action.payload.linkWeakId].masterLinkWeakId].linkWeakIds.filter(o => o !== action.payload.linkWeakId);
      state.links.allWeakIds = state.links.allWeakIds.filter(o => o !== action.payload.linkWeakId);
      delete state.links.byWeakId[action.payload.linkWeakId];
    },
    updateLinkTitle: (state, action: PayloadAction<IdValuePair>) => { 
      state.links.byWeakId[action.payload.id].title = action.payload.value;
    },
    updateLinkUrl: (state, action: PayloadAction<IdValuePair>) => {
      state.links.byWeakId[action.payload.id].url = action.payload.value;
    },
    setDraftMode: (state, action) => {
      state.isDraftMode = action.payload;
    },
    setSaveLoading: (state, action) => {
      state.saveLoading = action.payload;
    },
    reOrderMasterLinks: (state, action: PayloadAction<StringArrayUpdatePayload>) => {
      state.sections.byWeakId[action.payload.weakId].masterLinkWeakIds = action.payload.value;
    },
    populateLinksFromServer: (state, action: PayloadAction<LinkDataResponse>) => {
      const sections = [...action.payload.sections];
      sections.sort((a, b) => (a.index || 0) - (b.index || 0));
      state.sections.allWeakIds = sections.map(s => s.weakId);
      action.payload.sections.forEach(section => {
        section.editingMasterLinkWeakIds = [];
        state.sections.byWeakId[section.weakId] = section;
      });
      state.masterLinks.allWeakIds = action.payload.masterLinks.map(m => m.weakId);
      action.payload.masterLinks.forEach(ml => {
        state.masterLinks.byWeakId[ml.weakId] = ml;
      });
      state.links.allWeakIds = action.payload.links.map(l => l.weakId);
      action.payload.links.forEach(l => {
        state.links.byWeakId[l.weakId] = l;
      });
    },
    addEditingMasterLinkWid: (state, action: PayloadAction<IdValuePair>) => {
      state.sections.byWeakId[action.payload.id].editingMasterLinkWeakIds.push(action.payload.value);
    },
    removeEditingMasterLinkWid: (state, action: PayloadAction<IdValuePair>) => {
      const index = state.sections.byWeakId[action.payload.id].editingMasterLinkWeakIds.indexOf(action.payload.value);
      if (index > -1) {
        state.sections.byWeakId[action.payload.id].editingMasterLinkWeakIds.splice(index, 1);
      }
    },
    addCustomColor: (state, action: PayloadAction<IdValuePair>) => {
      state.masterLinks.byWeakId[action.payload.id].customColor = action.payload.value;
    },
    setCustomIcon: (state, action: PayloadAction<IdNullableValuePair>) => {
      state.masterLinks.byWeakId[action.payload.id].customIcon = action.payload.value;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setCustomIcon, addCustomColor, addEditingMasterLinkWid, removeEditingMasterLinkWid, addNewSection, saveLinkTypes, removeSection, updateMasterLinkTitle, updateSectionTitle, addMasterLink, removeMasterLink, updateMasterLinkType, addEmptyLink, removeLink, updateLinkTitle, updateLinkUrl, setDraftMode, reOrderMasterLinks, setSaveLoading, populateLinksFromServer } = profileLinksSlice.actions;

export default profileLinksSlice.reducer;